import React from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import {
  formatCapitalizeFirstLetter,
  formatDate,
} from "../../components/helper";
import ActionButtons from "../../components/action_buttons";

const Details = ({ onHide, rowData }) => {
  const fields = [
    {
      id: "createdDate",
      label: "Date",
      value: formatDate(rowData ? rowData.createdDate : null),
    },
    {
      id: "name",
      label: "Name",
      value: formatCapitalizeFirstLetter(rowData, "name") || "",
    },
    {
      id: "status",
      label: "Status",
      value: formatCapitalizeFirstLetter(rowData, "status") || "",
    },
    {
      id: "purpose",
      label: "Purpose",
      value: formatCapitalizeFirstLetter(rowData, "purpose") || "",
    },
    {
      id: "complaint",
      label: "Description",
      value: formatCapitalizeFirstLetter(rowData, "complaint") || "",
    },

  ];

  return (
    <>
      <div className="p-fluid formgrid grid">
        {fields.map((field) => (
          <div
            className={`field md:col-${field.id === "complaint" ? "12" : "6"}`}
            key={field.id}
          >
            <label htmlFor={field.id}>{field.label}</label>
            {field.id === "complaint" ? (
              <>
                <InputTextarea
                  id={field.id}
                  value={field.value}
                  readOnly
                  rows={5}
                />
              </>
            ) : (
              <InputText id={field.id} value={field.value} readOnly />
            )}
          </div>
        ))}
      </div>
      <div className="details-action-button">
        <ActionButtons onCancel={onHide} cancelLabel="Close" />
      </div>{" "}
    </>
  );
};

export default Details;
