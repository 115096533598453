import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../config";

const bannerReducer = createSlice({
  name: "banner",
  initialState: {
    bannerList: [],
    loading: false,
    error: null,
    addSuccess: undefined,
  },
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.addSuccess = undefined;
      state.deleteSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBannerList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBannerList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.bannerList = action.payload;
      })
      .addCase(getBannerList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createBanner.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.addSuccess = action.payload;
      })
      .addCase(createBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteBanner.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.deleteSuccess = undefined;
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.deleteSuccess = true;
        const filterData = state.bannerList.data.filter(
          (list) => list.id != action.payload.deletedBannerId
        );
        state.bannerList = { ...state.bannerList, data: filterData };
      })
      .addCase(deleteBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.deleteSuccess = false;
      })
      .addCase(updateBanner.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default bannerReducer.reducer;
export const { resetChangeStatus } = bannerReducer.actions;

// Thunk to fetch banner list
export const getBannerList = createAsyncThunk(
  "banner/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`${config.baseUrl}${config.bannerPath}`);
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to create a new banner
export const createBanner = createAsyncThunk(
  "banner/create",
  async (bannerData, { rejectWithValue }) => {
    try {
      const response = await Axios.post(
        `${config.baseUrl}${config.bannerPath}`,
        bannerData
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to delete a banner
export const deleteBanner = createAsyncThunk(
  "banner/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.delete(
        `${config.baseUrl}${config.bannerPath}/${id}`
      );
      return { data: response.data, deletedBannerId: id };
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to update a banner
export const updateBanner = createAsyncThunk(
  "banner/update",
  async (updatedBannerData, { rejectWithValue }) => {
    try {
      const { id, ...data } = updatedBannerData;
      const response = await Axios.patch( 
        `${config.baseUrl}${config.bannerPath}/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
