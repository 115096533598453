import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../config";

const donationReducer = createSlice({
  name: "donation",
  initialState: {
    donationList: [],
    loading: false,
    error: null,
    addSuccess: undefined,
    totalRecords: 0,
  },
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDonationList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDonationList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.donationList = action.payload;
        state.totalRecords = action.payload.count;
      })
      .addCase(getDonationList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createDonationType.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.addSuccess = undefined;
      })
      .addCase(createDonationType.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.addSuccess = true;
      })
      .addCase(createDonationType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.addSuccess = false;
      });
  },
});

export default donationReducer.reducer;
export const { resetChangeStatus } = donationReducer.actions;

// Thunk to fetch donation list
export const getDonationList = createAsyncThunk(
  "donation/fetch",
  async ({ fromDate, toDate, id, search, page, recordsPerPage }, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}${config.donationPath}?fromDate=${fromDate}&toDate=${toDate}&donationType=${id}&page=${page}&recordsPerPage=${recordsPerPage}&search=${search}`
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to post a new donation type
export const createDonationType = createAsyncThunk(
  "donation/post",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.post(
        "http://52.230.119.123:3030/api/donationType",
        data
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
