import React from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import {
  formatCapitalizeFirstLetter,
  formatDate,
} from "../../components/helper";
import ActionButtons from "../../components/action_buttons";
import config from "../../../../config";

const Details = ({ onHide, rowData }) => {
  const fields = [
    {
      id: "expirationDate",
      label: "Date",
      value: formatDate(rowData ? rowData.createdDate : null),
    },
    {
      id: "title",
      label: "Title",
      value: formatCapitalizeFirstLetter(rowData, "title") || "",
    },
    {
      id: "periorty",
      label: "Priority",
      value: formatCapitalizeFirstLetter(rowData, "periorty") || "",
    },
    {
      id: "announcement",
      label: "Announcement",
      value: formatCapitalizeFirstLetter(rowData, "announcement") || "",
    },
  ];

  return (
    <>
      <div className="p-fluid formgrid grid">
        {fields.map((field) => (
          <div
            className={`field md:col-${
              field.id === "announcement" ? "12" : "4"
            }`}
            key={field.id}
          >
            <label htmlFor={field.id}>{field.label}</label>
            {field.id === "announcement" ? (
              <>
                <InputTextarea
                  id={field.id}
                  value={field.value}
                  readOnly
                  rows={5}
                />
              </>
            ) : (
              <InputText id={field.id} value={field.value} readOnly />
            )}
          </div>
        ))}
      </div>
      <div className="col-12 md:12 p-fluid add_banner">
        <div className="flex">
          <div className="upload_detail_images">
            <h6> Image</h6>
            {rowData && rowData.icon && rowData.icon && (
              <img src={config.baseUrl + rowData.icon} alt="Icon Image" />
            )}
          </div>
        </div>
      </div>
      <div className="details-action-button">
        <ActionButtons onCancel={onHide} cancelLabel="Close" />
      </div>
    </>
  );
};

export default Details;
