import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { updateEvent, getEventList } from "../../../redux/event_slice";
import { useDispatch, useSelector } from "react-redux";
import addIcon from "../../../../assets/old_ravian_assests/add.png";
import config from "../../../../config";
import { toast } from "react-toastify";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import * as Yup from "yup";
import ActionButtons from "../../components/action_buttons";
import { Dropdown } from "primereact/dropdown";

const cities = [
  { label: "Lahore", value: "Lahore" },
  { label: "Islamabad", value: "Islamabad" },
  { label: "Karachi", value: "Karachi" },
  { label: "Quetta", value: "Quetta" },
  { label: "Peshawar", value: "Peshawar" },
];

const convert24HourIntTo12HourDate = (timeInt) => {
  if (!timeInt) return null;
  const hours = Math.floor(timeInt / 100);
  const minutes = timeInt % 100;
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
};

const convert12HourDateTo24HourInt = (date) => {
  if (!date) return null;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return hours * 100 + minutes;
};

const EditEvent = ({ onHide, eventData, formData }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const contentEditableRef = useRef(null);

  const loginUser = useSelector((state) => state.loginUser);
  const { user } = loginUser;

  const [isImageEdit, setIsImageEdit] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [changedFields, setChangedFields] = useState({});

  const validationSchema = Yup.object().shape({
    registrationOpeningDate: Yup.date().required("Opening date is required"),
    registrationClosingDate: Yup.date()
      .required("Closing date is required")
      .min(
        Yup.ref("registrationOpeningDate"),
        "Closing date must be greater than or equal to Opening date"
      ),
    eventDate: Yup.date()
      .required("Event date is required")
      .min(
        Yup.ref("registrationClosingDate"),
        "Event date must be greater than Closing date"
      )
      .min(
        Yup.ref("registrationOpeningDate"),
        "Event date must be greater than Opening date"
      ),
  });

  const formik = useFormik({
    initialValues: {
      registrationOpeningDate: eventData?.registrationOpeningDate || "",
      registrationClosingDate: eventData?.registrationClosingDate || "",
      eventDate: eventData?.eventDate || "",
      title: eventData?.title || "",
      fee: eventData?.fee || "",
      totalSeats: eventData?.totalSeats || "",
      eventStartTime:
        convert24HourIntTo12HourDate(eventData?.eventStartTime) || "",
      eventEndTime: convert24HourIntTo12HourDate(eventData?.eventEndTime) || "",
      location: eventData?.location || "",
      guidelines: Array.isArray(eventData?.guidelines)
        ? eventData.guidelines
        : [],
      description: eventData?.description || "",
      ticket: eventData?.ticket ? `${config.baseUrl}${eventData.ticket}` : "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (!formChanged) {
        onHide();
        return;
      }

      const updatePayload = {};
      Object.keys(changedFields).forEach((field) => {
        if (field === "ticket" && !isImageEdit) {
          return;
        }
        if (field === "eventStartTime" || field === "eventEndTime") {
          updatePayload[field] = convert12HourDateTo24HourInt(values[field]);
        } else {
          updatePayload[field] = values[field]; 
        }
      });
    

      const action = updateEvent({
        id: eventData.id,
        updatedBy: user?.data?.id,
        ...updatePayload,
      });

      dispatch(action)
        .then(() => {
          onHide();
          return dispatch(getEventList({}));
        })
        .catch((error) => {
          console.error("Update event error: ", error);
          toast.error("Failed to update event.");
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const handleFieldChange = (field, value) => {
    formik.setFieldValue(field, value);
    setFormChanged(true);
    setChangedFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };

  const handleFileUpload = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = async () => {
        const base64String = reader.result;
        setIsImageEdit(true);
        handleFieldChange("ticket", base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleContentChange = () => {
    const content = contentEditableRef.current.innerText;
    const lines = content
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line !== "");

    console.log("Updated guidelines lines:", lines);
    formik.setFieldValue("guidelines", lines);
    formik.setFieldTouched("guidelines", true);

    setChangedFields((prevFields) => ({
      ...prevFields,
      guidelines: true, // This should mark guidelines as changed
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      document.execCommand("insertHTML", false, "<br><li></li>");
      handleContentChange();
    }
  };

  const initializeContent = () => {
    if (
      contentEditableRef.current &&
      !contentEditableRef.current.innerText.trim()
    ) {
      const guidelinesList = formik.values.guidelines
        .map((guideline) => `<li>${guideline}</li>`)
        .join("");
      contentEditableRef.current.innerHTML = `<ul>${guidelinesList}</ul>`;
    }
  };

  useEffect(() => {
    initializeContent();
  }, [formik.values.guidelines]); // Depend on guidelines to update content

  useEffect(() => {
    if (eventData && eventData.guidelines) {
      formik.setFieldValue("guidelines", eventData.guidelines);
      initializeContent(); // Update contentEditable on eventData change
    }
  }, [eventData]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field  md:col-4">
            <label htmlFor="">Opening Date </label>
            <Calendar
              id="registrationOpeningDate"
              value={new Date(formik.values.registrationOpeningDate)}
              onChange={(e) =>
                handleFieldChange("registrationOpeningDate", e.value)
              }
              showIcon
              placeholder="Select Date"
              minDate={new Date()}
            />
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">Closing Date </label>
            <Calendar
              id="registrationClosingDate"
              value={new Date(formik.values.registrationClosingDate)}
              onChange={(e) =>
                handleFieldChange("registrationClosingDate", e.value)
              }
              showIcon
              placeholder="Select Date"
              minDate={new Date()}
            />
            {formik.errors.registrationClosingDate &&
              formik.touched.registrationClosingDate && (
                <small className="error">
                  {formik.errors.registrationClosingDate}
                </small>
              )}
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">Event Date </label>
            <Calendar
              id="eventDate"
              value={new Date(formik.values.eventDate)}
              onChange={(e) => handleFieldChange("eventDate", e.value)}
              showIcon
              placeholder="Select Date"
              minDate={new Date()}
            />
            {formik.touched.eventDate && formik.errors.eventDate && (
              <small className="error">{formik.errors.eventDate}</small>
            )}
          </div>
          <div className="field md:col-4 responsive-col">
            <label htmlFor="title">Title</label>
            <InputText
              id="title"
              value={formik.values.title}
              placeholder="Add title"
              maxLength={30}
              onChange={(e) => handleFieldChange("title", e.target.value)}
            />
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">Ticket Fee</label>
            <InputText
              placeholder="Enter fee"
              id="fee"
              name="fee"
              value={formik.values.fee}
              onChange={(e) => handleFieldChange("fee", e.target.value)}
              maxLength={5}
              onKeyUp={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                formik.setFieldValue("fee", e.target.value);
              }}
            />
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">Total Seats</label>
            <InputText
              placeholder="Enter seats no"
              id="totalSeats"
              maxLength={8}
              value={formik.values.totalSeats}
              onChange={(e) => handleFieldChange("totalSeats", e.target.value)}
              onKeyUp={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                formik.setFieldValue("totalSeats", e.target.value);
              }}
            />
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">Start Time</label>
            <Calendar
              id="eventStartTime"
              name="eventStartTime"
              value={formik.values.eventStartTime}
              onChange={(e) =>
                handleFieldChange("eventStartTime", e.target.value)
              }
              timeOnly
              hourFormat="12"
              placeholder="Enter start time"
            />
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">End Time</label>
            <Calendar
              id="eventEndTime"
              name="eventEndTime"
              value={formik.values.eventEndTime}
              onChange={(e) =>
                handleFieldChange("eventEndTime", e.target.value)
              }
              timeOnly
              hourFormat="12"
              placeholder="Enter end time"
            />
          </div>
          <div className="field md:col-4">
            <label htmlFor="city">Location</label>
            <Dropdown
              id="city"
              value={formik.values.location?.city}
              options={cities}
              placeholder="Select City"
              onChange={(e) =>
                formik.setFieldValue("location", {
                  ...formik.values.location,
                  city: e.value,
                })
              }
            />
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">
              Address<span className="asteric">&nbsp;*</span>
            </label>
            <InputText
              placeholder="Enter address"
              id="address"
              maxLength={30}
              value={formik.values.location?.address}
              onChange={(e) =>
                formik.setFieldValue("location", {
                  ...formik.values.location,
                  address: e.target.value,
                })
              }
            />
          </div>
          <div className="field md:col-12">
            <label htmlFor="guidelines">
              Guidelines<span className="asteric">&nbsp;*</span>
            </label>
            <div
              id="guidelines"
              ref={contentEditableRef}
              contentEditable
              onInput={handleContentChange}
              onKeyDown={handleKeyPress}
              className="bulets-input"
            />
          </div>
          <div className="field  md:col-12">
            <label htmlFor="">Description</label>
            <InputTextarea
              placeholder="Enter description"
              id="description"
              rows={5}
              value={formik.values.description}
              onChange={(e) => handleFieldChange("description", e.target.value)}
            />
          </div>
          <div className="field md:col-6 responsive-col">
            <label htmlFor="file-upload" className="block mb-2 upload_images">
              Upload File
              {!formik.values.ticket ? (
                <>
                  <div className="upload-box">
                    <img src={addIcon} alt="Add Icon" />
                  </div>
                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                    accept=".png, .jpg, .jpeg, .svg"
                  />
                </>
              ) : (
                <div className="upload-box">
                  <img src={formik.values.ticket} alt="Banner Preview" />
                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                    accept=".png, .jpg, .jpeg, .svg"
                  />
                </div>
              )}
            </label>
          </div>
        </div>

        <ActionButtons
          showSave
          onCancel={onHide}
          onSave={formik.handleSubmit}
          loading={formik.isSubmitting}
          saveLabel={formData ? "Update" : "Update"}
          cancelLabel="Close"
        />
      </form>
    </>
  );
};

export default EditEvent;
