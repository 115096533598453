import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../../config";

const categoryReducer = createSlice({
  name: "category",
  initialState: {
    categoryList: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.addSuccess = undefined;
      state.deleteSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
   
      .addCase(createCategory.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.addSuccess = action.payload;
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCategoryList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategoryList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.categoryList = action.payload;
      })
      .addCase(getCategoryList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default categoryReducer.reducer;
export const { resetChangeStatus } = categoryReducer.actions;


// Thunk to fetch category list
export const getCategoryList = createAsyncThunk(
  "category/fetch",
  async ({year}, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}${config.categoryPath}?year=${year}`
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);



// Thunk to create a new category
export const createCategory = createAsyncThunk(
  "category/create",
  async (categoryData, { rejectWithValue }) => {
    try {
      const response = await Axios.post(
        `${config.baseUrl}${config.categoryPath}`,
        categoryData
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);



