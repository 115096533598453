import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

const ComplaintChart = ({ complaints }) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (!complaints || !complaints.length) {
      setChartData({});
      setChartOptions({});
      return;
    }

    const complaintData = complaints[0];
    const openPercentage = Math.round(parseFloat(complaintData.opens));
    const closedPercentage = Math.round(parseFloat(complaintData.closed));

    const data = {
      labels: ["Open", "Closed"],
      datasets: [
        {
          data: [openPercentage, closedPercentage],
          backgroundColor: ["#4BA9EE", "#F1AD07"],
          hoverBackgroundColor: ["#4BA9EE", "#F1AD07"],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          display: false,
          position: "bottom",
          labels: {
            usePointStyle: false,
            boxWidth: 15,
            padding: 30,
          },
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (context) {
              const label = context.label || "";
              const value = context.raw || 0;
              return `${label}: ${value}`;
            },
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [complaints]);

  return (
    <div className="flex justify-content-center">
      <Chart type="doughnut" data={chartData} options={chartOptions} />
    </div>
  );
};

export default ComplaintChart;
