import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";

const membershipReducer = createSlice({
  name: "membership",
  initialState: {
    membershipList: [],
    loading: false,
    error: null,
    totalRecords: 0,
  },
  reducers: {
    resetMembershipState(state, action) {
      state.membershipList = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMembershipList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMembershipList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.membershipList = action.payload;
        state.totalRecords = action.payload.count;
      })
      .addCase(getMembershipList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(updateMembershipStatus.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateMembershipStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        // Update state based on the response
      })
      .addCase(updateMembershipStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default membershipReducer.reducer;
export const { resetMembershipState } = membershipReducer.actions;

// Thunk to fetch membership list
export const getMembershipList = createAsyncThunk(
  "membership/fetch",
  async (
    { fromDate, toDate, status, search, page, recordsPerPage },
    { rejectWithValue }
  ) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}${config.membershipPath}?status=${status}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&recordsPerPage=${recordsPerPage}&search=${search}`
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to update membership status
export const updateMembershipStatus = createAsyncThunk(
  "membership/updateStatus",
  async ({ id, approve, comment }, { rejectWithValue }) => {
    try {
      const requestBody = { approve };
      if (!approve && comment) {
        requestBody.comment = comment;
      }

      const response = await Axios.patch(
        `${config.baseUrl}${config.membershipPath}/updateDocumentStatus/${id}`,
        requestBody
      );
      const membershipStatus = response.data.approve ? "approved" : "rejected";
      return { status: membershipStatus, comment: response.data.comment };
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Failed to update membership status";

      throw rejectWithValue(errorMessage);
    }
  }
);
