import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { getUserList, deleteUser } from "../../../redux/auth_slice/user_slice";
import { getRoleList } from "../../../redux/auth_slice/role_slice";
import editIcon from "../../../../assets/old_ravian_assests/edit_icon.png";
import deleteIcon from "../../../../assets/old_ravian_assests/delete.png";
import editIconRed from "../../../../assets/old_ravian_assests/edit_icon_red.png";
import addIcon from "../../../../assets/old_ravian_assests/add.png";
import {  confirmDialog } from "primereact/confirmdialog";
import { formatCapitalizeFirstLetter } from "../../components/helper";
import config from "../../../../config";
import { toast } from "react-toastify";
import Loader from "../../components/loader";
import EditAddForm from "./edit_add_form";
import CustomImagePreview from "../../components/custom_imagepreview";

export default function CommitteeScreen() {
  const userReducerState = useSelector((state) => state.user || {});
  const { userList, loading: userLoading } = userReducerState;

  const roleReducerState = useSelector((state) => state.role || {});
  const { roleList, loading: roleLoading } = roleReducerState;

  const [filterdList, setFilteredList] = useState();
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRoleList({}));
  }, [dispatch]);

  useEffect(() => {
    if (roleList && roleList.data && roleList.data.length > 0) {
      const staffRole = roleList.data.find((role) => role.role === "staff");
      if (staffRole) {
        dispatch(
          getUserList({
            id: staffRole.id,
            page: 1,
            isExecutive: true,
            recordsPerPage: 10,
          })
        );
      } else {
        console.error("Staff role not found");
      }
    }
  }, [roleList, dispatch]);

  useEffect(() => {
    if (userList) {
      setFilteredList(userList?.data);
    }
  }, [userList]);

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setGlobalFilter(searchValue);
    const filteredData = userList?.data?.filter(
      (user) =>
        user.name.toLowerCase().includes(searchValue) ||
        user.phone.toLowerCase().includes(searchValue) ||
        user.designation.toLowerCase().includes(searchValue) ||
        user.email.toLowerCase().includes(searchValue)
    );
    setFilteredList(filteredData);
  };

  const confirmDelete = (deleteId) => {
    confirmDialog({
      message: "Are you sure you want to delete this User?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        dispatch(deleteUser(deleteId));
        toast.success("Member deleted successfully");
      },
    });
  };
  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const onHide = () => {
    setShowDialog(false);
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          className="p-button-rounded p-button-edit p-0"
          onClick={() => {
            toggleDialogMode(true);
            setSelectedUser(rowData);
          }}
          aria-label="Edit"
        >
          <img src={editIcon} alt="Edit" />
        </Button>
        <Button
          className="p-button-rounded p-button-delete"
          onClick={() => {
            confirmDelete(rowData.id);
          }}
          aria-label="Delete"
        >
          <img src={deleteIcon} alt="Delete" />
        </Button>
      </div>
    );
  };

  const imageTemplate = (rowData) => {
    return (
      <div>
        <CustomImagePreview src={config.baseUrl + rowData?.profile} />
      </div>
    );
  };

  const columnsData = [
    {
      field: "serialNumber",
      header: "Sr. No",
      body: (_, col) => col.rowIndex + 1,
    },
    {
      field: "name",
      header: "Name",
      body: (rowData) => formatCapitalizeFirstLetter(rowData, "name"),
    },
    {
      field: "phone",
      header: "Mobile No.",
    },
    {
      field: "designation",
      header: "Designation",
    },
    {
      field: "email",
      header: "Email",
    },
    {
      header: "Image",
      body: imageTemplate,
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-image">
              <img src={isEdit ? editIconRed : addIcon} alt="Icon" />
            </div>
            <div className="popup-header-text">
              {isEdit ? "Edit Member" : "Add Member"}
            </div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        {/* {isEdit ? (
          <EditCommittee onHide={onHide} memberData={selectedUser} />
        ) : (
          <AddCommittee onHide={onHide} />
        )} */}
        <EditAddForm onHide={onHide} formData={isEdit ? selectedUser : null} />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        {/* <div className="col-12 md:col-10 justify-content-end filter-responsive">
          <Button
            label="Add Member"
            icon="pi pi-plus"
            className="p-button ml-3"
            onClick={() => toggleDialogMode(false)}
          />
        </div> */}
      </div>

      <div className="card">
        {userLoading && <Loader />}
        <DataTable
          scrollable
          scrollHeight="500px"
          emptyMessage="No record found."
          value={filterdList}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
        >
          {columnsData?.map((data, index) => {
            return (
              <Column
                key={index}
                field={data.field}
                header={data.header}
                body={data.body}
              />
            );
          })}
        </DataTable>
      </div>
    </>
  );
}
