import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

const DonationtypeChart = ({ donationTypeStates }) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (!donationTypeStates || !donationTypeStates.length) {
      setChartData({});
      setChartOptions({});
      return;
    }

    const labels = donationTypeStates.map((item) => item.name);
    const dataValues = donationTypeStates.map(
      (item) => parseInt(item.amount, 10) || 0
    );

    const data = {
      labels: labels,
      datasets: [
        {
          data: dataValues,
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
            "#FF6384",
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
            "#FF6384",
          ],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          display: false,
          position: "bottom",
          labels: {
            usePointStyle: false,
            boxWidth: 15,
            // padding: 30
          },
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (context) {
              const label = context.label || "";
              const value = context.raw || 0;
              return `${label}: ${value}`;
            },
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [donationTypeStates]);

  return (
    <div className="flex justify-content-center">
      <Chart type="doughnut" data={chartData} options={chartOptions} />
    </div>
  );
};

export default DonationtypeChart;
