import React from "react";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { Checkbox } from "primereact/checkbox";
import { Password } from "primereact/password";

export default function CustomInputField({
  iden,
  formik,
  type = "text",
  className,
  label,
  placeHolder,
  options,
  optionLabel = "name",
  optionValue = "value",
  mask,
  onChange,
  value,
  feedback,
  disabled = false,
}) {
  function titleCase(str) {
    return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
  }

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className='p-error'>{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className={`field ${className}`}>
      {label && type !== "checkbox" && (
        <label htmlFor={iden}>
          {label ? titleCase(label) : titleCase(iden)}
        </label>
      )}

      {type === "checkbox" ? (
        <div className='field-checkbox'>
          <Checkbox
            name={iden}
            checked={formik.values[iden]}
            onChange={formik.handleChange}
          />
          <label>{label || titleCase(iden)}</label>
        </div>
      ) : type === "dropdown" ? (
        <Dropdown
          placeholder={placeHolder}
          id={iden}
          name={iden}
          options={options}
          optionLabel={optionLabel}
          optionValue={optionValue}
          value={formik.values[iden]}
          onChange={formik.handleChange}
          className={classNames("", {
            "p-invalid": isFormFieldValid({ iden }),
          })}
        />
      ) : type === "password" ? (
        <Password
          id={iden}
          name={iden}
          value={value || formik.values[iden]}
          onChange={onChange || formik.handleChange}
          type={type}
          placeholder={placeHolder}
          className={classNames({ "p-invalid": isFormFieldValid({ iden }) })}
          toggleMask
          feedback={feedback}
        />
      ) : type === "textArea" || type === "textarea" ? (
        <InputTextarea
          id={iden}
          name={iden}
          value={formik.values[iden]}
          onChange={formik.handleChange}
          type='text'
          placeholder={placeHolder}
          className={classNames({ "p-invalid": isFormFieldValid({ iden }) })}
        />
      ) : mask !== undefined ? (
        <InputMask
          id={iden}
          mask={mask}
          name={iden}
          value={value || formik.values[iden]}
          onChange={onChange || formik.handleChange}
          type={type}
          placeholder={placeHolder}
          className={classNames({ "p-invalid": isFormFieldValid({ iden }) })}
        />
      ) : type === "number" ? (
        <InputText
          id={iden}
          name={iden}
          value={formik.values[iden]}
          onChange={formik.handleChange}
          type='number'
          placeholder={placeHolder}
          className={classNames({ "p-invalid": isFormFieldValid({ iden }) })}
        />
      ) : (
        <InputText
          disabled={disabled}
          autoComplete='off'
          autoSave='off'
          id={iden}
          name={iden}
          value={formik.values[iden]}
          onChange={formik.handleChange}
          type={type}
          placeholder={placeHolder}
          className={classNames("customInput", {
            "p-invalid": isFormFieldValid({ iden }),
          })}
        />
      )}
      {onChange === undefined && getFormErrorMessage(iden)}
    </div>
  );
}
