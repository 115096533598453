import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../../config";

const userReducer = createSlice({
  name: "user",
  initialState: {
    userList: [],
    loading: false,
    error: null,
    totalRecords: 0,
  },
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.deleteSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.userList = action.payload;
        state.totalRecords = action.payload.count;
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.deleteSuccess = undefined;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.deleteSuccess = true;
        const filterData = state.userList.data.filter(
          (list) => list.id != action.payload.deletedUserId
        );
        state.userList = {
          ...state.userList,
          data: filterData,
        };
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.deleteSuccess = false;
      })
      .addCase(updateUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userReducer.reducer;
export const { resetChangeStatus } = userReducer.actions;

// Thunk to fetch user list
export const getUserList = createAsyncThunk(
  "user/fetch",
  async (
    { id, page, recordsPerPage, isExecutive, search },
    { rejectWithValue }
  ) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}${config.authPath}?role=${id}&page=${page}&isExecutive=${isExecutive}&recordsPerPage=${recordsPerPage}&search=${search}`
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to update user status
export const updateUser = createAsyncThunk(
  "user/update",
  async (updatedMemberData, { rejectWithValue }) => {
    try {
      const { id, ...data } = updatedMemberData;
      const response = await Axios.patch(
        `${config.baseUrl}${config.authPath}/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to delete user list
export const deleteUser = createAsyncThunk(
  "user/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.delete(
        `${config.baseUrl}${config.authPath}/${id}`
      );
      return { data: response.data, deletedUserId: id };
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
