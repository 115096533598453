import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import EditHelpStatus from "./edit_help_status";
import { useDispatch, useSelector } from "react-redux";
import { getHelpList } from "../../../redux/help_slice";
import editIcon from "../../../../assets/old_ravian_assests/edit_icon.png";
import editIconRed from "../../../../assets/old_ravian_assests/edit_icon_red.png";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import {
  formatDate,
  formatCapitalizeFirstLetter,
  statusColors,
  exportCSV,
  renderTextWithTooltip
} from "../../components/helper";
import { format } from "date-fns";
import Loader from "../../components/loader";
import Paginator from "../../components/paginator";
import useDebounce from "../../components/hooks/useDebounce";
import Details from "./details";

export default function HelpScreen() {
  const helpReducer = useSelector((state) => state.help || {});
  const { helpList, loading, totalRecords } = helpReducer;

  const [filterdList, setFilteredList] = useState();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const [idValue, setIdValue] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [filterSelectedStatus, setFilterSelectedStatus] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [dateRange, setDateRange] = useState([
    new Date("2024-01-01"),
    new Date("2024-12-30"),
  ]);
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const debouncedGlobalFilter = useDebounce(globalFilter, 500);

  const dispatch = useDispatch();
  const fromDate =
    dateRange?.[0] && format(new Date(dateRange?.[0]), "yyyy-MM-dd");
  const toDate =
    dateRange?.[0] && format(new Date(dateRange?.[1]), "yyyy-MM-dd");

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      dispatch(
        getHelpList({
          fromDate: fromDate,
          toDate: toDate,
          status: filterSelectedStatus,
          type: null,
          search: debouncedGlobalFilter || null,
          page: page,
          recordsPerPage: rows,
        })
      );
    }
  }, [
    dispatch,
    dateRange,
    filterSelectedStatus,
    page,
    rows,
    debouncedGlobalFilter,
  ]);

  useEffect(() => {
    if (helpList) {
      setFilteredList(helpList?.data);
    }
  }, [helpList]);

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
  };

  const onHide = () => {
    setShowEditDialog(false);
    setShowDetailsDialog(false);
  };

  const handleEditClick = (rowData) => {
    setIdValue(rowData.id);
    setStatusValue(rowData.status);
    setShowEditDialog(true);
  };

  const handleDetailsClick = (rowData) => {
    setSelectedData(rowData);
    setShowDetailsDialog(true);
  };

  const actionTemplate = (rowData) => {
    const isResolved = rowData.status === "Resolved";

    return (
      <div className="flex align-items-center">
        {!isResolved ? (
          <Button
            className="p-button-rounded p-button-edit p-0 mr-5"
            onClick={() => handleEditClick(rowData)}
            aria-label="Edit"
          >
            <img src={editIcon} alt="Edit" />
          </Button>
        ) : (
          <Button
            className="p-button-rounded p-button-edit p-0 mr-5"
            disabled
            aria-label="Delete"
          >
            <img src={editIcon} alt="Edit" className="edit-icon-disabled" />
          </Button>
        )}
        <Button
          className="secondary-btn"
          aria-label="Details"
          onClick={() => handleDetailsClick(rowData)}
        >
          Details
        </Button>
      </div>
    );
  };

  const filterStatusValue = [
    { label: "Pending", value: "Pending" },
    { label: "In-Process", value: "In-Process" },
    { label: "Resolved", value: "Resolved" },
  ];

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    setRows(event.rows);
  };

  const exportCSVFile = () => {
    dispatch(
      getHelpList({
        fromDate: "2024-01-01",
        toDate: "2024-02-21",
        status: filterSelectedStatus,
      })
    ).then(() => {
      if (helpList && helpList.data) {
        const csvData = helpList.data.map((item, index) => ({
          "Sr No": index + 1,
          "User Name": formatCapitalizeFirstLetter(item, "name"),
          "Problem Description": formatCapitalizeFirstLetter(item, "complaint"),
          Status: formatCapitalizeFirstLetter(item, "status"),
          Date: formatDate(item.createdDate),
        }));
        exportCSV(csvData, "help_data.csv");
      }
    });
  };

  const dt = useRef(null);

  const serialNumberTemplate = (rowData, column) => {
    return column.rowIndex + 1 + first;
  };

  const columnsData = [
    {
      header: "Sr. No",
      body: serialNumberTemplate,
    },
    {
      field: "name",
      header: "User Name",
      body: (rowData) => formatCapitalizeFirstLetter(rowData, "name"),
    },
    {
      field: "complaint",
      header: "Problem Description",
      body: (rowData) => renderTextWithTooltip(rowData, "complaint", "bottom"),
    },
    {
      field: "status",
      header: "Status",
      body: statusColors,
    },
    {
      field: "createdDate",
      header: "Date",
      body: (rowData) => formatDate(rowData.createdDate),
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const rowClassName = "p-datatable-clickable";

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-image">
              {showEditDialog && <img src={editIconRed} alt="Edit Icon" />}
            </div>
            <div className="popup-header-text">
              {showDetailsDialog && "Help Details"}
              {showEditDialog && "Edit Help"}
            </div>
          </div>
        }
        visible={showDetailsDialog || showEditDialog}
        onHide={onHide}
        className="responsive-dialog"
      >
        {showDetailsDialog && (
          <Details
            onHide={() => onHide("showDetailsDialog")}
            rowData={selectedData}
          />
        )}
        {showEditDialog && (
          <EditHelpStatus
            id={idValue}
            initialStatus={statusValue}
            rowData={selectedData}
            onHide={() => onHide("showAddDialog")}
          />
        )}
      </Dialog>

      <div className="grid  align-items-center">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        <div className="col-12 md:col-10  justify-content-end filter-responsive">
          <Dropdown
            className="status__dropdown dropdown-size mt-3"
            value={filterSelectedStatus}
            onChange={(e) =>
              setFilterSelectedStatus(e.value !== undefined ? e.value : null)
            }
            optionValue="value"
            options={filterStatusValue}
            optionLabel="label"
            placeholder="Select Status"
            showClear
          />

          <Calendar
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            readOnlyInput
            showIcon
            className="calender-icon"
          />

          <Button
            className="p-button export_button"
            icon="pi pi-upload"
            label="Export to Excel"
            onClick={exportCSVFile}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          scrollable
          scrollHeight="500px"
          ref={dt}
          emptyMessage="No record found."
          value={filterdList}
          className="custom-data-table"
          onRowClick={(event) => handleDetailsClick(event.data)}
          rowClassName={rowClassName}
        >
          {columnsData?.map((data, index) => {
            return (
              <Column
                key={index}
                field={data.field}
                header={data.header}
                body={data.body}
              />
            );
          })}
        </DataTable>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
}
