import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { updateMembershipStatus } from "../../../redux/membership_slice";

const EditMembershipStatus = ({ onHide, id, initialStatus }) => {
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(initialStatus || "");
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();

  const handleUpdateStatus = async () => {
    try {
      setLoading(true);

      if (selectedStatus !== "reject" && selectedStatus !== "approve") {
        throw new Error(`Invalid status: ${selectedStatus}`);
      }
      const approveValue = selectedStatus === "approve" ? true : false;
      const response = dispatch(
        updateMembershipStatus({ id, approve: approveValue })
      );
      toast.success("Status updated successfully");
      onHide();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="grid">
        <div className="col-12 md:col-12 p-fluid">
          <Dropdown
            className="status__dropdown mt-3"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            options={
              initialStatus === "approve"
                ? [{ label: "Approve", value: "approve" }]
                : [
                    { label: "Reject", value: "reject" },
                    { label: "Approve", value: "approve" },
                  ]
            }
            optionLabel="label"
            placeholder="Select"
            disabled={initialStatus === "approve"}
          />
          <div>
            <label style={{ fontWeight: "700", color: "#7C251F" }}>
              Comment
            </label>
            <InputText
              className="comment-input mt-3"
              placeholder="Enter your comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="button-resp mx-auto flex">
        <Button
          label="Cancel"
          onClick={onHide}
          className="p-button-outlined mr-2"
          type="button"
        />
        <Button
          label={loading ? "Updating..." : "Update"}
          onClick={handleUpdateStatus}
          className="p-button"
          disabled={!selectedStatus || loading}
        />
      </div>
    </>
  );
};

export default EditMembershipStatus;
