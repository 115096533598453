import React, { useEffect, useState, useCallback } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "../../../redux/auth_slice/user_slice";
import { getRoleList } from "../../../redux/auth_slice/role_slice";
import editIcon from "../../../../assets/old_ravian_assests/edit_icon.png";
import editIconRed from "../../../../assets/old_ravian_assests/edit_icon_red.png";
import addIcon from "../../../../assets/old_ravian_assests/add.png";
import { formatCapitalizeFirstLetter , formatDate, renderTextWithTooltip} from "../../components/helper";
import config from "../../../../config";
import { Tag } from "primereact/tag";
import Loader from "../../components/loader";
import EditAddForm from "./edit_add_form";
import Paginator from "../../components/paginator";
import useDebounce from "../../components/hooks/useDebounce";
import CustomImagePreview from "../../components/custom_imagepreview";

export default function UserVerificationScreen() {
  const userReducerState = useSelector((state) => state.user || {});
  const { userList, loading: userLoading, totalRecords } = userReducerState;
  const roleReducerState = useSelector((state) => state.role || {});
  const { roleList, loading: roleLoading } = roleReducerState;
  const dispatch = useDispatch();

  const [filteredList, setFilteredList] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const debouncedGlobalFilter = useDebounce(globalFilter, 500);

  useEffect(() => {
    dispatch(getRoleList({}));
  }, [dispatch]);

  useEffect(() => {
    const userRole = roleList?.data?.find((role) => role.role === "user");
    if (userRole) {
      if (debouncedGlobalFilter && debouncedGlobalFilter.length > 0) {
        fetchUserList(userRole.id, debouncedGlobalFilter);
      } else {
        fetchUserList(userRole.id);
      }
    }
  }, [debouncedGlobalFilter, roleList, page, rows]);

  const fetchUserList = (roleId, search = null) => {
    dispatch(
      getUserList({
        id: roleId,
        page: page,
        isExecutive: false,
        recordsPerPage: rows,
        search: search ? search : null,
      })
    );
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    setRows(event.rows);
  };

  useEffect(() => {
    if (userList?.data) {
      setFilteredList(userList.data);
    }
  }, [userList]);

  const handleGlobalFilterChange = (e) => {
    let searchValue = e.target.value.toLowerCase();
    const isPhoneNumberSearch = /^\+\d+/.test(searchValue);
    if (isPhoneNumberSearch) {
      searchValue = searchValue.replace(/^\+/, "");
    }
    setGlobalFilter(searchValue.length > 0 ? searchValue : null);
  };

  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const onHide = () => {
    setShowDialog(false);
  };

  const actionTemplate = (rowData) => {
    const is_verified = rowData?.is_verified;

    return (
      <div className="flex">
        <Button
          className="p-button-rounded p-button-edit p-0"
          onClick={() => {
            if (!is_verified) {
              toggleDialogMode(true);
              setSelectedUser(rowData);
            }
          }}
          aria-label="Edit"
        >
          <img
            src={editIcon}
            alt="Edit"
            className={is_verified ? "edit-icon-disabled" : ""}
          />
        </Button>
      </div>
    );
  };

  const imageTemplate = (rowData) => {
    return (
      <div>
        <CustomImagePreview src={config.baseUrl + rowData?.profile} />
      </div>
    );
  };

  const transcriptImage = (rowData) => {
    const imageUrl = config.baseUrl + rowData?.document;
    
    return (
      <div>
        {rowData?.document ? (
          <CustomImagePreview src={imageUrl} />
        ) : (
          <div className="no-image-placeholder">
            --
          </div>
        )}
      </div>
    );
  };

  const verifiedTemplate = (rowData) => {
    return rowData.is_verified ? (
      <Tag severity="success" icon="pi pi-check" value="Verified" />
    ) : (
      <Tag severity="danger" icon="pi pi-times" value="Unverified" />
    );
  };

  const serialNumberTemplate = (rowData, column) => {
    return column.rowIndex + 1 + first;
  };

  const columnsData = [
    {
      header: "Sr. No",
      body: serialNumberTemplate,
    },
    {
      field: "name",
      header: "Name",
      body: (rowData) => formatCapitalizeFirstLetter(rowData, "name", "bottom"),
    },
    {
      field: "phone",
      header: "Mobile No.",
      body: (rowData) => rowData.phone || "--",
    },
    {
      field: "cnic",
      header: "CNIC",
      body: (rowData) => rowData.cnic || "--",
    },
    {
      field: "email",
      header: "Email",
      body: (rowData) => rowData.email || "--",
    },
    {
      field: "program",
      header: "Program",
      body: (rowData) => rowData.program?.programType || "--",
    },
    {
      field: "createdDate",
      header: "Date",
      body: (rowData) => formatDate(rowData.createdDate),
    },
    {
      header: "Image",
      body: imageTemplate,
    },
    {
      header: "Transcript",
      body: transcriptImage,
    },
    {
      header: "Verified",
      body: verifiedTemplate,
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-image">
              <img src={isEdit ? editIconRed : addIcon} alt="Icon" />
            </div>
            <div className="popup-header-text">
              {isEdit ? "Edit User Status" : ""}
            </div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="responsive-dialog"
      >
        <EditAddForm
          onHide={onHide}
          formData={isEdit ? selectedUser : null}
          currentPage={page}
          rowsPerPage={rows}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>
      </div>

      <div className="card">
        {userLoading && <Loader />}
        <DataTable
          scrollable
          scrollHeight="500px"
          emptyMessage="No record found."
          value={filteredList}
          className="custom-data-table"
        >
          {columnsData?.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
}
