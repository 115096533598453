import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";

const eventReducer = createSlice({
  name: "event",
  initialState: {
    eventList: [],
    loading: false,
    error: null,
    addSuccess: undefined,
  },
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.addSuccess = undefined;
      state.deleteSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEventList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.eventList = action.payload;
      })
      .addCase(getEventList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteEvent.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.deleteSuccess = undefined;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.deleteSuccess = true;
        const filterData = state.eventList.data.filter(
          (list) => list.id != action.payload.deletedEventId
        );
        state.eventList = { ...state.eventList, data: filterData };
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.deleteSuccess = false;
      })
      .addCase(createEvent.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.addSuccess = action.payload;
        toast.success("Event Added successfully");
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error("Failed to add event");
      })
      .addCase(updateEvent.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default eventReducer.reducer;
export const { resetChangeStatus } = eventReducer.actions;

// Thunk to fetch event list
export const getEventList = createAsyncThunk(
  "event/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`${config.baseUrl}${config.eventPath}`);
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to delete a event
export const deleteEvent = createAsyncThunk(
  "event/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.delete(
        `${config.baseUrl}${config.eventPath}/${id}`
      );
      return { data: response.data, deletedEventId: id };
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to create a new event
export const createEvent = createAsyncThunk(
  "event/create",
  async (eventData, { rejectWithValue }) => {
    try {
      const response = await Axios.post(
        `${config.baseUrl}${config.eventPath}`,
        eventData
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to update a event
export const updateEvent = createAsyncThunk(
  "event/update",
  async (updatedEventData, { rejectWithValue }) => {
    try {
      const { id, ...data } = updatedEventData;
      const response = await Axios.patch(
        `${config.baseUrl}${config.eventPath}/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
