import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../../config";

const initialState = {
  galleryList: [],
  loading: false,
  error: null,
  addSuccess: undefined,
  updateSuccess: undefined,
  deleteSuccess: undefined,
};

const galleryReducer = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.addSuccess = undefined;
      state.deleteSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGalleryList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGalleryList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.galleryList = action.payload;
      })
      .addCase(getGalleryList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createGallery.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.addSuccess = action.payload;
      })
      .addCase(createGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteGallery.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.deleteSuccess = undefined;
      })
      .addCase(deleteGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.deleteSuccess = true;
        const filterData = state.galleryList.data.filter(
          (list) => list.id != action.payload.deletedGalleryId
        );
        state.galleryList = {
          ...state.galleryList,
          data: filterData,
        };
      })
      .addCase(deleteGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.deleteSuccess = false;
      });
  },
});

// Export the reducer and actions
export default galleryReducer.reducer;
export const { resetChangeStatus } = galleryReducer.actions;

// Thunk to create a new gallery
export const createGallery = createAsyncThunk(
  "gallery/create",
  async (galleryData, { rejectWithValue }) => {
    try {
      const response = await Axios.post(
        `${config.baseUrl}${config.galleryPath}`,
        galleryData
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to fetch gallery list
export const getGalleryList = createAsyncThunk(
  "gallery/fetch",
  async ({ date, type, page, category, year }, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}${config.galleryPath}?date=${date}&type=${type}&page=${page}&category=${category}&year=${year}`
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to delete gallery list
export const deleteGallery = createAsyncThunk(
  "gallery/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.delete(
        `${config.baseUrl}${config.galleryPath}/${id}`
      );
      return { data: response.data, deletedGalleryId: id };
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
