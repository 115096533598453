import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateUser, getUserList } from "../../../redux/auth_slice/user_slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import addIcon from "../../../../assets/old_ravian_assests/add.png";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import config from "../../../../config";
import { toast } from "react-toastify";
import ActionButtons from "../../components/action_buttons";

const EditAddForm = ({ onHide, formData }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isImageEdit, setIsImageEdit] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: formData?.name || "",
      phone: formData?.phone || "",
      email: formData?.email || "",
      description: formData?.description || "",
      designation: formData?.designation || "",
      profile: formData ? `${config.baseUrl}${formData.profile}` : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please add a name"),
      phone: Yup.string().required("Please add a phone number"),
      email: Yup.string().required("Please add an email"),
      description: Yup.string().required("Please add a description"),
      designation: Yup.string().required("Please add a designation"),
      profile: Yup.string().required("Please upload an image"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!formChanged) {
        onHide();
        return;
      }
      const updatePayload = {
        name: values.name,
        phone: values.phone,
        designation: values.designation,
        email: values.email,
        description: values.description,
        profile: isImageEdit ? values.profile : undefined,
      };

      try {
        if (formData) {
          await dispatch(updateUser({ id: formData.id, ...updatePayload }));
          toast.success("Member updated successfully");
        }
        onHide();
        await dispatch(
          getUserList({
            id: formData?.role?.id,
            page: 1,
            isExecutive: true,
            recordsPerPage: 10,
          })
        );
      } catch (error) {
        toast.error(`Failed to ${formData ? "update" : "add"} member`);
      }
    },
  });

  const handleFileUpload = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = async () => {
        const base64String = reader.result;
        setIsImageEdit(true);
        formik.setFieldValue("profile", base64String);
        setFormChanged(true);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid ">
          <div className="field  md:col-6 responsive-col">
            <label htmlFor="">
              {" "}
              Name{!formData && <span className="asteric">&nbsp;*</span>}
            </label>
            <InputText
              id="name"
              value={formik.values.name}
              placeholder="Add Name"
              onChange={(e) => {
                formik.handleChange(e);
                setFormChanged(true);
              }}
            />
            {formik.touched.name && formik.errors.name && (
              <small className="error">{formik.errors.name}</small>
            )}
          </div>
          <div className="field  md:col-6 responsive-col">
            <label htmlFor="">
              {" "}
              Phone{!formData && <span className="asteric">&nbsp;*</span>}
            </label>
            <InputText
              id="phone"
              value={formik.values.phone}
              placeholder="Add phone"
              onChange={(e) => {
                formik.handleChange(e);
                setFormChanged(true);
              }}
            />
            {formik.touched.phone && formik.errors.phone && (
              <small className="error">{formik.errors.phone}</small>
            )}
          </div>
          <div className="field  md:col-6 responsive-col">
            <label htmlFor="">
              {" "}
              Designation{!formData && <span className="asteric">&nbsp;*</span>}
            </label>
            <InputText
              id="designation"
              value={formik.values.designation}
              placeholder="Add designation"
              onChange={(e) => {
                formik.handleChange(e);
                setFormChanged(true);
              }}
            />
            {formik.touched.designation && formik.errors.designation && (
              <small className="error">{formik.errors.designation}</small>
            )}
          </div>
          <div className="field  md:col-6 responsive-col">
            <label htmlFor="">
              {" "}
              Email{!formData && <span className="asteric">&nbsp;*</span>}
            </label>
            <InputText
              id="email"
              value={formik.values.email}
              placeholder="Add email"
              onChange={(e) => {
                formik.handleChange(e);
                setFormChanged(true);
              }}
            />
            {formik.touched.email && formik.errors.email && (
              <small className="error">{formik.errors.email}</small>
            )}
          </div>
          <div className="field md:col-12 responsive-col">
            <label htmlFor="description">
              Description{!formData && <span className="asteric">&nbsp;*</span>}
            </label>
            <InputTextarea
              id="description"
              name="description"
              value={formik.values.description}
              onChangeCapture={(e) => {
                formik.handleChange(e);
                setFormChanged(true);
              }}
              rows={5}
              cols={30}
              className={
                formik.errors.description && formik.touched.description
                  ? "p-invalid"
                  : null
              }
            />
            {formik.touched.description && formik.errors.description && (
              <small className="error">{formik.errors.description}</small>
            )}
          </div>
          <div className="field md:col-4 responsive-col">
            <label htmlFor="file-upload" className="block mb-2 upload_images">
              Upload File{!formData && <span className="asteric">&nbsp;*</span>}
              {!formik.values.profile ? (
                <>
                  <div className="upload-box">
                    <img src={addIcon} alt="Add Icon" />
                  </div>
                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                    accept=".png, .jpg, .jpeg, .svg"
                  />
                </>
              ) : (
                <div className="upload-box">
                  <img src={formik.values.profile} alt="Banner Preview" />
                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                    accept=".png, .jpg, .jpeg, .svg"
                  />
                </div>
              )}
              {formik.touched.profile && formik.errors.profile && (
                <small className="error">{formik.errors.profile}</small>
              )}
            </label>
          </div>
        </div>

        <ActionButtons
          showSave
          onCancel={onHide}
          onSave={formik.handleSubmit}
          loading={formik.isSubmitting}
          saveLabel={formData ? "Update" : "Add"}
          cancelLabel="Close"
        />
      </form>
    </>
  );
};

export default EditAddForm;
