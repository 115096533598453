import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../../config";

const roleReducer = createSlice({
  name: "role",
  initialState: {
    roleList: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.addSuccess = undefined;
      state.deleteSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoleList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRoleList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.roleList = action.payload;
      })
      .addCase(getRoleList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default roleReducer.reducer;
export const { resetChangeStatus } = roleReducer.actions;

// Thunk to fetch role list
export const getRoleList = createAsyncThunk(
  "role/fetch",
  async ({}, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`${config.baseUrl}${config.rolePath}`);
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
