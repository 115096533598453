import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import CustomInputField from "../../components/custom_input_field";
import { loginUser } from "../../../redux/auth_slice/login_user_slice";
import logo from "../../../../assets/old_ravian_assests/logo.png";
import { useHistory } from "react-router-dom";

export default function LoginScreen() {
  const { loading } = useSelector((state) => state.loginUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem("rememberedEmail") || "",
      password: "",
      rememberMe: !!localStorage.getItem("rememberedEmail"),
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Password is required.")
        .min(8, "Minimum length should be 8"),
      email: Yup.string()
        .required("Email is required.")
        .email("Invalid email format"),
    }),
    onSubmit: async (data) => {
   
      if (data.rememberMe) {
        localStorage.setItem("rememberedEmail", data.email);
       
      } else {
        localStorage.removeItem("rememberedEmail");
      }

      dispatch(loginUser(data)).then(res=>{
      if(res.payload?.data){
       return history.push('/');
      }
      history.push('/login'); 
      })
    },
  });

  return (
    <>
      <div className='auth_main'>
        <div className='auth_login_box'>
          <div className='auth-logo'>
            <img src={logo} />
            <span>Old Ravians Union</span>
          </div>
          <h2 className='auth-welcome mt-3 text-align-center'>
            Log In to Continue...
          </h2>

          <form
            className=' p-fluid justify-content-center align-items-left mt-5'
            onSubmit={formik.handleSubmit}
          >
            <CustomInputField
            label={'Email or Username'}
              iden='email'
              formik={formik}
              placeHolder='Enter email'
              type='email'
            />

            <CustomInputField
            label={'Password'}
              iden='password'
              formik={formik}
              placeHolder='Enter password'
              type='password'
              feedback={false}
            />

            <Button
              type='submit'
              className='customButton'
              label='Log In'
              loading={loading}
            />
          </form>
        </div>
      </div>
    </>
  );
}
