import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { createEvent, getEventList } from "../../../redux/event_slice";
import { useDispatch, useSelector } from "react-redux";
import addIcon from "../../../../assets/old_ravian_assests/add.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import { format } from "date-fns";
import { InputTextarea } from "primereact/inputtextarea";
import ActionButtons from "../../components/action_buttons";
import { Dropdown } from "primereact/dropdown";

const cities = [
  { label: "Lahore", value: "Lahore" },
  { label: "Islamabad", value: "Islamabad" },
  { label: "Karachi", value: "Karachi" },
  { label: "Quetta", value: "Quetta" },
  { label: "Peshawar", value: "Peshawar" },
];

const AddEvent = ({ onHide }) => {
  const [loading, setLoading] = useState(false);

  const [iconImage, setIconImage] = useState(null);
  const [iconUrl, setIconUrl] = useState("");
  const [imageError, setImageError] = useState(false);
  const [guidelines, setGuidelines] = useState([""]);

  const [ticket, setTicket] = useState("");

  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.loginUser);
  const { user } = loginUser;

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setIconImage(file);
      setIconUrl(URL.createObjectURL(file));
      setTicket(file);
      setImageError(false);
      formik.setFieldValue("ticket", file);
    } else {
      setIconImage(null);
      setIconUrl(null);
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    openingDate: Yup.mixed().required("Opening date is required"),
    closingDate: Yup.mixed()
      .when("openingDate", (openingDate, schema) => {
        return openingDate
          ? schema
              .test({
                test: function (value) {
                  return value && value >= openingDate;
                },
                message: "Closing date must be after or equal to opening date",
              })
              .required(
                "Closing date is required when opening date is provided"
              )
          : schema;
      })
      .nullable()
      .typeError("Closing date must be a valid date"),
    totalSeats: Yup.number().required("Total seats is required"),
    eventDate: Yup.mixed()
      .when(
        ["openingDate", "closingDate"],
        (openingDate, closingDate, schema) => {
          return schema.test({
            test: function (value) {
              if (!value || !openingDate || !closingDate) {
                return true;
              }
              return value > openingDate && value >= closingDate;
            },
            message:
              "Event date must be after the opening date and after or equal to the closing date",
          });
        }
      )
      .required("Event date is required"),
    eventStartTime: Yup.mixed().required("Start time is required"),
    eventEndTime: Yup.mixed().when(
      "eventStartTime",
      (eventStartTime, schema) => {
        return eventStartTime
          ? schema
              .test({
                test: function (value) {
                  return value && value > eventStartTime;
                },
                message: "End time must be after the start time",
              })
              .required("End time is required")
          : schema;
      }
    ),
    fee: Yup.number().required("Fee is required"),
    location: Yup.object().shape({
      city: Yup.string().required("City is required"),
      address: Yup.string().required("Address is required"),
    }),
    guidelines: Yup.array().of(Yup.string()).min(1, "Guideline is required"),
    ticket: Yup.string().required("Image is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      openingDate: "",
      closingDate: "",
      totalSeats: "",
      eventDate: "",
      eventStartTime: "",
      eventEndTime: "",
      fee: "",
      location: {
        city: "",
        address: "",
      },
      guidelines: [""],
      ticket: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      handleAddEvent(values);
    },
  });

  const handleAddEvent = () => {
    setLoading(true);

    if (ticket) {
      const reader = new FileReader();
      reader.onloadend = () => {
        let base64Ticket = reader.result.split(",")[1];
        base64Ticket = "data:image/jpeg;base64," + base64Ticket;
        dispatchCreateEvent(base64Ticket);
      };
      reader.readAsDataURL(ticket);
    } else {
      dispatchCreateEvent(null);
    }
  };

  const formatTimeToHHmm = (time) => {
    if (!time) return "";
    return parseInt(format(new Date(time), "HHmm"), 10);
  };

  const dispatchCreateEvent = (base64Ticket) => {
    dispatch(
      createEvent({
        title: formik.values.title,
        description: formik.values.description,
        registrationOpeningDate: formik.values.openingDate,
        registrationClosingDate: formik.values.closingDate,
        totalSeats: formik.values.totalSeats,
        eventDate: formik.values.eventDate,
        eventStartTime: formatTimeToHHmm(formik.values.eventStartTime),
        eventEndTime: formatTimeToHHmm(formik.values.eventEndTime),
        guidelines: formik.values.guidelines,
        location: formik.values.location,
        fee: formik.values.fee,
        ticket: base64Ticket,
        createdBy: user?.data?.id,
      })
    )
      .then((response) => {
        setLoading(false);
        onHide();
        dispatch(getEventList({}));
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const contentEditableRef = useRef(null);

  const handleContentChange = () => {
    const content = contentEditableRef.current.innerText;
    const lines = content
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line !== "");
    setGuidelines(lines);
    formik.setFieldValue("guidelines", lines);
    formik.setFieldTouched("guidelines", true);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      document.execCommand("insertHTML", false, "<br><li></li>");
      handleContentChange();
    }
  };

  const initializeContent = () => {
    if (
      contentEditableRef.current &&
      !contentEditableRef.current.innerText.trim()
    ) {
      contentEditableRef.current.innerHTML = "<ul><li></li></ul>";
    }
  };

  React.useEffect(() => {
    initializeContent();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field  md:col-4">
            <label htmlFor="">
              Opening Date<span className="asteric">&nbsp;*</span>
            </label>
            <Calendar
              id="openingDate"
              value={formik.values.openingDate}
              onChange={(e) => formik.setFieldValue("openingDate", e.value)}
              showIcon
              placeholder="Select Date"
              minDate={new Date()}
            />
            {formik.errors.openingDate && formik.touched.openingDate && (
              <small className="error">{formik.errors.openingDate}</small>
            )}
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">
              Closing Date<span className="asteric">&nbsp;*</span>
            </label>
            <Calendar
              id="closingDate"
              value={formik.values.closingDate}
              onChange={(e) =>
                formik.setFieldValue("closingDate", e.target.value)
              }
              showIcon
              placeholder="Select Date"
              minDate={new Date()}
            />
            {formik.errors.closingDate && formik.touched.closingDate && (
              <small className="error">{formik.errors.closingDate}</small>
            )}
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">
              Event Date<span className="asteric">&nbsp;*</span>
            </label>
            <Calendar
              id="eventDate"
              value={formik.values.eventDate}
              onChange={(e) =>
                formik.setFieldValue("eventDate", e.target.value)
              }
              showIcon
              placeholder="Select Date"
              minDate={new Date()}
            />
            {formik.errors.eventDate && formik.touched.eventDate && (
              <small className="error">{formik.errors.eventDate}</small>
            )}
          </div>

          <div className="field  md:col-4">
            <label htmlFor="">
              Title<span className="asteric">&nbsp;*</span>
            </label>
            <InputText
              placeholder="Enter title"
              id="title"
              maxLength={30}
              value={formik.values.title || ""}
              onChange={formik.handleChange}
            />
            {formik.errors.title && formik.touched.title && (
              <small className="error">{formik.errors.title}</small>
            )}
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">
              Ticket Fee<span className="asteric">&nbsp;*</span>
            </label>
            <InputText
              placeholder="Enter fee"
              id="fee"
              name="fee"
              value={formik.values.fee || ""}
              onChange={formik.handleChange}
              maxLength={5}
              onKeyUp={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                formik.setFieldValue("fee", e.target.value);
              }}
            />
            {formik.errors.fee && formik.touched.fee && (
              <small className="error">{formik.errors.fee}</small>
            )}
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">
              Total Seats<span className="asteric">&nbsp;*</span>
            </label>
            <InputText
              placeholder="Enter seats no"
              id="totalSeats"
              maxLength={8}
              value={formik.values.totalSeats || ""}
              onChange={formik.handleChange}
              onKeyUp={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                formik.setFieldValue("totalSeats", e.target.value);
              }}
            />
            {formik.errors.totalSeats && formik.touched.totalSeats && (
              <small className="error">{formik.errors.totalSeats}</small>
            )}
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">
              Start Time<span className="asteric">&nbsp;*</span>
            </label>
            <Calendar
              id="eventStartTime"
              name="eventStartTime"
              value={formik.values.eventStartTime || ""}
              onChange={formik.handleChange}
              timeOnly
              hourFormat="12"
              placeholder="Enter start time"
            />

            {formik.errors.eventStartTime && formik.touched.eventStartTime && (
              <small className="error">{formik.errors.eventStartTime}</small>
            )}
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">
              End Time<span className="asteric">&nbsp;*</span>
            </label>
            <Calendar
              id="eventEndTime"
              name="eventEndTime"
              value={formik.values.eventEndTime || ""}
              onChange={formik.handleChange}
              timeOnly
              hourFormat="12"
              placeholder="Enter end time"
            />

            {formik.errors.eventEndTime && formik.touched.eventEndTime && (
              <small className="error">{formik.errors.eventEndTime}</small>
            )}
          </div>
          <div className="field md:col-4">
            <label htmlFor="city">
              Location<span className="asteric">&nbsp;*</span>
            </label>
            <Dropdown
              id="city"
              value={formik.values.location?.city || ""}
              options={cities}
              placeholder="Select City"
              onChange={(e) =>
                formik.setFieldValue("location", {
                  ...formik.values.location,
                  city: e.value,
                })
              }
            />
            {formik.errors.location?.city && formik.touched.location?.city && (
              <small className="error">{formik.errors.location?.city}</small>
            )}
          </div>
          <div className="field  md:col-4">
            <label htmlFor="">
              Address<span className="asteric">&nbsp;*</span>
            </label>
            <InputText
              placeholder="Enter address"
              id="address"
              maxLength={30}
              value={formik.values.location?.address || ""}
              onChange={(e) =>
                formik.setFieldValue("location", {
                  ...formik.values.location,
                  address: e.target.value,
                })
              }
            />
            {formik.errors.location?.address &&
              formik.touched.location?.address && (
                <small className="error">
                  {formik.errors.location?.address}
                </small>
              )}
          </div>

          <div className="field md:col-12">
            <label htmlFor="guidelines">
              Guidelines<span className="asteric">&nbsp;*</span>
            </label>
            <div
              id="guidelines"
              ref={contentEditableRef}
              contentEditable
              onInput={handleContentChange}
              onKeyDown={handleKeyPress}
              className="bulets-input"
            />
            {formik.errors.guidelines && formik.touched.guidelines && (
              <small className="error">{formik.errors.guidelines}</small>
            )}
          </div>

          <div className="field  md:col-12">
            <label htmlFor="">
              Description<span className="asteric">&nbsp;*</span>
            </label>
            <InputTextarea
              placeholder="Enter description"
              id="description"
              rows={5}
              value={formik.values.description || ""}
              onChange={formik.handleChange}
            />
            {formik.errors.description && formik.touched.description && (
              <small className="error">{formik.errors.description}</small>
            )}
          </div>
        </div>

        <div className="md:col-4 p-fluid add_banner">
          <label htmlFor="ticket" className="block mb-2 upload_images">
            Upload Image
            <span className="asteric">&nbsp;*</span>
            {!iconUrl ? (
              <>
                <div className="upload-box">
                  <img src={addIcon} />
                </div>
                <input
                  id="ticket"
                  type="file"
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg, .svg"
                />
              </>
            ) : (
              <div className="upload-box">
                <img src={iconUrl} alt="Banner Preview" />
                <input
                  id="ticket"
                  type="file"
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg, .svg"
                />
              </div>
            )}
            {formik.errors.ticket && formik.touched.ticket && (
              <small className="error">{formik.errors.ticket}</small>
            )}
          </label>
        </div>

        <ActionButtons
          showSave
          onCancel={onHide}
          onSave={formik.handleSubmit}
          loading={formik.isSubmitting}
          saveLabel={"Add"}
          cancelLabel="Close"
        />
      </form>
    </>
  );
};

export default AddEvent;
