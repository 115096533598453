import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";

import {
  formatCapitalizeFirstLetter,
  formatDate,
} from "../../components/helper";
import { updateHelpStatus } from "../../../redux/help_slice";
import { toast } from "react-toastify";
import ActionButtons from "../../components/action_buttons";

const EditHelpStatus = ({ onHide, id, initialStatus, rowData }) => {
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(initialStatus || "");
  const [formChanged, setFormChanged] = useState(false);

  const dispatch = useDispatch();

  const handleUpdateStatus = async () => {
    if (!formChanged) {
      onHide();
      return;
    }
    try {
      setLoading(true);
      if (selectedStatus !== "In-Process" && selectedStatus !== "Resolved") {
        throw new Error(`Invalid status: ${selectedStatus}`);
      }
      const response = dispatch(
        updateHelpStatus({ id, status: selectedStatus })
      );
      toast.success("Status updated successfully");
      onHide();
    } catch (error) {
      toast.error("Failed to update status");
    } finally {
      setLoading(false);
    }
  };

  const fields = [
    {
      id: "createdDate",
      label: "Date",
      value: formatDate(rowData ? rowData.createdDate : null),
    },
    {
      id: "name",
      label: "Name",
      value: formatCapitalizeFirstLetter(rowData, "name") || "",
    },
    {
      id: "status",
      label: "Status",
      value: formatCapitalizeFirstLetter(rowData, "status") || "",
    },
    {
      id: "purpose",
      label: "Purpose",
      value: formatCapitalizeFirstLetter(rowData, "purpose") || "",
    },
    {
      id: "complaint",
      label: "Description",
      value: formatCapitalizeFirstLetter(rowData, "complaint") || "",
    },
  ];

  return (
    <>
      <div className="p-fluid formgrid grid ">
        {fields.map((field) => (
          <div
            className={`field md:col-${field.id === "complaint" ? "12" : "6"}`}
            key={field.id}
          >
            <label htmlFor={field.id}>{field.label}</label>
            {field.id === "complaint" ? (
              <>
                <InputTextarea
                  id={field.id}
                  value={field.value}
                  disabled
                  readOnly
                  rows={5}
                />
              </>
            ) : (
              <InputText id={field.id} value={field.value} readOnly disabled />
            )}
          </div>
        ))}

        <div className="field md:col-12 responsive-col">
          <label htmlFor="year">Status</label>
          <Dropdown
            className="status__dropdown mt-3"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              setFormChanged(true);
            }}
            options={[
              { label: "In-Process", value: "In-Process" },
              { label: "Resolved", value: "Resolved" },
            ]}
            optionLabel="label"
            placeholder="Select"
          />
        </div>
      </div>

      <ActionButtons
        showSave
        onCancel={onHide}
        onSave={handleUpdateStatus}
        loading={loading}
        saveLabel="Update"
        cancelLabel="Close"
      />
    </>
  );
};

export default EditHelpStatus;
