import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateAnnouncement,
  getAnnouncementList,
  createAnnouncement,
} from "../../../redux/news_slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Calendar } from "primereact/calendar";
import addIcon from "../../../../assets/old_ravian_assests/add.png";
import { InputText } from "primereact/inputtext";
import config from "../../../../config";
import ActionButtons from "../../components/action_buttons";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";

const EditAddForm = ({ onHide, formData, currentPage, rowsPerPage }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isImageEdit, setIsImageEdit] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [changedFields, setChangedFields] = useState({});

  const formik = useFormik({
    initialValues: {
      expirationDate: formData?.expirationDate || "",
      title: formData?.title || "",
      announcement: formData?.announcement || "",
      periorty: formData?.periorty || "",
      icon: formData ? `${config.baseUrl}${formData.icon}` : "",
    },
    validationSchema: Yup.object({
      expirationDate: Yup.date().required("Please add expiry date"),
      title: Yup.string().required("Please add title"),
      announcement: Yup.string().required("Please add a description"),
      periorty: Yup.string().required("Please select priority"),
      icon: Yup.string().required("Please upload an image"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      if (!formChanged) {
        onHide();
        return;
      }

      const updatePayload = {};
      Object.keys(changedFields).forEach((field) => {
        if (field === "icon" && !isImageEdit) {
          return;
        }
        updatePayload[field] = values[field];
      });

      const action = formData
        ? updateAnnouncement({ id: formData.id, ...updatePayload })
        : createAnnouncement(values);

      dispatch(action)
        .then(() => {
          onHide();
          return dispatch(
            getAnnouncementList({
              date: null,
              periorty: null,
              page: currentPage,
              recordsPerPage: rowsPerPage,
              search: null,
            })
          );
        })
        .catch((error) => {})
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const handleFieldChange = (field, value) => {
    formik.setFieldValue(field, value);
    setFormChanged(true);
    setChangedFields((prevFields) => ({
      ...prevFields,
      [field]: true,
    }));
  };

  const handleFileUpload = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = async () => {
        const base64String = reader.result;
        setIsImageEdit(true);
        handleFieldChange("icon", base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const priorityOptions = [
    { label: "Low", value: "low" },
    { label: "Medium", value: "medium" },
    { label: "High", value: "high" },
  ];

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field md:col-4 responsive-col">
            <label htmlFor="expirationDate">
              Expire Date
              {!formData && <span className="asteric">&nbsp;*</span>}
            </label>
            <Calendar
              id="expirationDate"
              value={new Date(formik.values.expirationDate)}
              onChange={(e) => handleFieldChange("expirationDate", e.value)}
              showIcon
              placeholder="Select Date"
              minDate={new Date()}
            />
            {formik.touched.expirationDate && formik.errors.expirationDate && (
              <small className="error">{formik.errors.expirationDate}</small>
            )}
          </div>
          <div className="field md:col-4 responsive-col">
            <label htmlFor="title">
              Title
              {!formData && <span className="asteric">&nbsp;*</span>}
            </label>
            <InputText
              id="title"
              value={formik.values.title}
              placeholder="Add title"
              onChange={(e) => handleFieldChange("title", e.target.value)}
            />
            {formik.touched.title && formik.errors.title && (
              <small className="error">{formik.errors.title}</small>
            )}
          </div>
          <div className="field md:col-4 responsive-col">
            <label htmlFor="priority">
              Priority
              {!formData && <span className="asteric">&nbsp;*</span>}
            </label>
            <Dropdown
              id="priority"
              value={formik.values.periorty}
              options={priorityOptions}
              onChange={(e) => handleFieldChange("periorty", e.target.value)}
              placeholder="Select Priority"
              className="status__dropdown m-0"
            />
            {formik.touched.periorty && formik.errors.periorty && (
              <small className="error">{formik.errors.periorty}</small>
            )}
          </div>
          <div className="field md:col-12 responsive-col">
            <label htmlFor="announcement">
              Announcement
              {!formData && <span className="asteric">&nbsp;*</span>}
            </label>
            <InputTextarea
              rows={5}
              id="announcement"
              value={formik.values.announcement}
              onChange={(e) =>
                handleFieldChange("announcement", e.target.value)
              }
            />
            {formik.touched.announcement && formik.errors.announcement && (
              <small className="error">{formik.errors.announcement}</small>
            )}
          </div>
          <div className="field md:col-4 responsive-col">
            <label htmlFor="file-upload" className="block mb-2 upload_images">
              Upload File
              {!formData && <span className="asteric">&nbsp;*</span>}
              {!formik.values.icon ? (
                <>
                  <div className="upload-box">
                    <img src={addIcon} alt="Add Icon" />
                  </div>
                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                    accept=".png, .jpg, .jpeg, .svg"
                  />
                </>
              ) : (
                <div className="upload-box">
                  <img src={formik.values.icon} alt="Banner Preview" />
                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                    accept=".png, .jpg, .jpeg, .svg"
                  />
                </div>
              )}
              {formik.touched.icon && formik.errors.icon && (
                <small className="error">{formik.errors.icon}</small>
              )}
            </label>
          </div>
        </div>

        <ActionButtons
          showSave
          onCancel={onHide}
          onSave={formik.handleSubmit}
          loading={formik.isSubmitting}
          saveLabel={formData ? "Update" : "Add"}
          cancelLabel="Close"
        />
      </form>
    </>
  );
};

export default EditAddForm;
