import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

const BarChart = ({ membershipState }) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (!Array.isArray(membershipState) || membershipState.length === 0) {
      return;
    }

    const maleCollection = membershipState.map(
      (item) => parseInt(item.male, 10) || 0
    );
    const femaleCollection = membershipState.map(
      (item) => parseInt(item.female, 10) || 0
    );
    const months = membershipState.map((item) => {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return monthNames[item.month - 1];
    });

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    const data = {
      labels: months,
      datasets: [
        {
          label: "Male Membership",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: maleCollection,
        },
        {
          label: "Female Membership",
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          data: femaleCollection,
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,

      plugins: {
        legend: {
            display: true, 
            position: 'bottom',
            labels: {
                usePointStyle: false,
                boxWidth: 15,
                padding: 30
            }
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (context) {
              return context.dataset.label + ": " + context.raw;
            },
          },
        },
      },

      scales: {
          x: {
              ticks: {
                  color: textColorSecondary,
                  font: {
                      weight: 500
                  }
              },
              grid: {
                  display: false,
                  drawBorder: false
              }
          },
          y: {
              ticks: {
                  color: textColorSecondary,
                  callback: function(value) {
                      return Number.isInteger(value) ? value : '';
                  }
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              },
             
              suggestedMin: 0, 
              suggestedMax: Math.max(...maleCollection, ...femaleCollection) + 100,
            }
      }
  };
  

   
    setChartData(data);
    setChartOptions(options);
  }, [membershipState]);

  return (
    <div className="">
      <Chart type="bar" data={chartData} options={chartOptions} />
    </div>
  );
};

export default BarChart;
