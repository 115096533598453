import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../config";

const helpReducer = createSlice({
  name: "help",
  initialState: {
    helpList: [],
    loading: false,
    error: null,
    totalRecords: 0,
  },
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.addSuccess = undefined;
      state.deleteSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHelpList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHelpList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.helpList = action.payload;
        state.totalRecords = action.payload.count;
      })
      .addCase(getHelpList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateHelpStatus.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(updateHelpStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id, status } = action.meta.arg;
        const updatedList = state.helpList.data.map((d) =>
          d.id === id ? { ...d, status } : d
        );
        state.helpList = { ...state.helpList, data: updatedList };
      })

      .addCase(updateHelpStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default helpReducer.reducer;
export const { resetChangeStatus } = helpReducer.actions;

// Thunk to fetch help list
export const getHelpList = createAsyncThunk(
  "help/fetch",
  async (
    { fromDate, toDate, status, type, search, page, recordsPerPage },
    { rejectWithValue }
  ) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}${config.complaintPath}?fromDate=${fromDate}&toDate=${toDate}&status=${status}&type=${type}&search=${search}&page=${page}&recordsPerPage=${recordsPerPage}`
      );

      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to update help status
export const updateHelpStatus = createAsyncThunk(
  "help/updateStatus",
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const response = await Axios.patch(
        `${config.baseUrl}${config.complaintPath}/${id}`,
        { status }
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
