import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { getEventList, deleteEvent } from "../../../redux/event_slice";
import editIcon from "../../../../assets/old_ravian_assests/edit_icon.png";
import deleteIcon from "../../../../assets/old_ravian_assests/delete.png";
import editIconRed from "../../../../assets/old_ravian_assests/edit_icon_red.png";
import addIcon from "../../../../assets/old_ravian_assests/add.png";
import { confirmDialog } from "primereact/confirmdialog";
import {
  formatDate,
  formatCapitalizeFirstLetter,
  exportCSV,
  renderTextWithTooltip,
} from "../../components/helper";
import config from "../../../../config";
import Details from "./details";
import AddEvent from "./add_event";
import EditEvent from "./edit_event";
import { toast } from "react-toastify";
import Loader from "../../components/loader";
import CustomImagePreview from "../../components/custom_imagepreview";

export default function Event() {
  const eventReducer = useSelector((state) => state.event || {});
  const { eventList, loading } = eventReducer;

  const [filterdList, setFilteredList] = useState();
  const [globalFilter, setGlobalFilter] = useState("");
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventList({}));
  }, [dispatch]);

  useEffect(() => {
    if (eventList) {
      setFilteredList(eventList?.data);
    }
  }, [eventList]);

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
    const filterdData = eventList.data?.filter(
      (list) =>
        list.title.toLowerCase().includes(searchValue.toLowerCase()) ||
        list.description.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredList(filterdData);
  };

  const onHide = () => {
    setShowAddDialog(false);
    setShowEditDialog(false);
    setShowDetailsDialog(false);
  };

  const handleEditClick = (rowData) => {
    setSelectedEvent(rowData);
    setShowEditDialog(true);
  };

  const handleDetailsClick = (rowData) => {
    setSelectedEvent(rowData);
    setShowDetailsDialog(true);
  };

  let deleteId;
  const confirmDeleteEvent = () => {
    confirmDialog({
      message: "Are you sure you want to delete this event?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        dispatch(deleteEvent(deleteId));
        toast.success("Event deleted successfully");
      },
    });
  };

  const actionTemplate = (rowData) => {
    const currentDate = new Date();

    const eventDate = new Date(rowData.eventDate);
    const eventPassed = eventDate < currentDate;

    return (
      <div className="flex">
         {!eventPassed ? (
        <Button
          className="p-button-rounded p-button-edit p-0"
          onClick={() => handleEditClick(rowData)}
          aria-label="Edit"
        >
          <img src={editIcon} alt="Edit" />
        </Button>
         ) : (
          <Button
          className="p-button-rounded p-button-edit p-0"
          aria-label="Edit"
        >
          <img src={editIcon} alt="Edit" className="edit-icon-disabled"/>
        </Button>
         )}
        {eventPassed ? (
          <Button
            className="p-button-rounded p-button-delete"
            onClick={() => {
              deleteId = rowData.id;
              confirmDeleteEvent();
            }}
            aria-label="Delete"
          >
            <img src={deleteIcon} alt="Delete" />
          </Button>
        ) : (
          <Button
            className="p-button-rounded p-button-delete"
            disabled
            aria-label="Delete"
          >
            <img src={deleteIcon} alt="Delete" className="edit-icon-disabled" />
          </Button>
        )}
        <Button
          className="secondary-btn"
          onClick={() => {
            handleDetailsClick(rowData);
          }}
          aria-label="Details"
        >
          Details
        </Button>
      </div>
    );
  };

  const imageTemplate = (rowData) => {
    const handleImageClick = (event) => {
      event.stopPropagation();
    };
    return (
      <div onClick={handleImageClick}>
        {rowData.ticket && rowData.ticket && (
          <CustomImagePreview src={config.baseUrl + rowData.ticket} />
        )}
      </div>
    );
  };

  const exportCSVFile = () => {
    dispatch(getEventList({})).then(() => {
      if (eventList && eventList.data) {
        const csvData = eventList.data.map((item, index) => ({
          "Sr No": index + 1,
          Title: formatCapitalizeFirstLetter(item, "title"),
          Description: formatCapitalizeFirstLetter(item, "description"),
          "Event Date": formatDate(item.eventDate),
          "Ticket Price": `PKR ${item.fee}`,
        }));
        exportCSV(csvData, "event_data.csv");
      }
    });
  };

  const dt = useRef(null);

  const columnsData = [
    {
      field: "serialNumber",
      header: "Sr. No",
      body: (_, col) => col.rowIndex + 1,
    },
    {
      field: "title",
      header: "Title",
      body: (rowData) => formatCapitalizeFirstLetter(rowData, "title"),
    },
    {
      field: "description",
      header: "Description",
      body: (rowData) =>
        renderTextWithTooltip(rowData, "description", "bottom"),
    },
    {
      field: "eventDate",
      header: "Event Date",
      body: (rowData) => formatDate(rowData.eventDate),
    },
    {
      header: "Image",
      body: imageTemplate,
    },
    {
      field: "fee",
      header: "Ticket Price",
      body: (rowData) => `PKR ${rowData.fee}`,
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const rowClassName = "p-datatable-clickable";

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-image">
              {showAddDialog && <img src={addIcon} alt="Add Icon" />}
              {showEditDialog && <img src={editIconRed} alt="Edit Icon" />}
            </div>
            <div className="popup-header-text">
              {showAddDialog && "Add Event"}
              {showDetailsDialog && "Event Details"}
              {showEditDialog && "Edit Event"}
            </div>
          </div>
        }
        visible={showAddDialog || showDetailsDialog || showEditDialog}
        onHide={onHide}
        className="banner-dialog"
        style={{ width: "60vw" }}
      >
        {showAddDialog && <AddEvent onHide={() => onHide("showAddDialog")} />}
        {showDetailsDialog && (
          <Details
            onHide={() => onHide("showDetailsDialog")}
            rowData={selectedEvent}
          />
        )}
        {showEditDialog && (
          <EditEvent
            onHide={() => onHide("showEditDialog")}
            eventData={selectedEvent}
          />
        )}
      </Dialog>

      <div className="grid align-items-center mb-3 mt-2">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        <div className="col-12 md:col-10 justify-content-end filter-responsive">
          <Button
            label="Add Event"
            icon="pi pi-plus"
            onClick={() => setShowAddDialog(true)}
            className="p-button ml-3"
          />
          <Button
            label="Export to Excel"
            icon="pi pi-upload"
            onClick={exportCSVFile}
            className="p-button ml-3"
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          ref={dt}
          emptyMessage="No record found."
          value={filterdList}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          onRowClick={(event) => handleDetailsClick(event.data)}
          rowClassName={rowClassName}
        >
          {columnsData?.map((data, index) => {
            return (
              <Column
                key={index}
                field={data.field}
                header={data.header}
                body={data.body}
              />
            );
          })}
        </DataTable>
      </div>
    </>
  );
}
