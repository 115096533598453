import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../config";

const dashboardReducer = createSlice({
  name: "dashboard",
  initialState: {
    dashboardList: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.addSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDashboardList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.dashboardList = action.payload;
      })
      .addCase(getDashboardList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default dashboardReducer.reducer;
export const { resetChangeStatus } = dashboardReducer.actions;

// Thunk to fetch dashboard list
export const getDashboardList = createAsyncThunk(
  "dashboard/fetch",
  async ({}, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}${config.dashboardPath}`
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
