import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  getAnnouncementList,
  deleteAnnouncement,
} from "../../../redux/news_slice";
import { useDispatch, useSelector } from "react-redux";
import {
  formatCapitalizeFirstLetter,
  formatDate,
  statusColors,
  exportCSV,
  renderTextWithTooltip
} from "../../components/helper";
import { Dialog } from "primereact/dialog";
import deleteIcon from "../../../../assets/old_ravian_assests/delete.png";
import editIconRed from "../../../../assets/old_ravian_assests/edit_icon_red.png";
import addIcon from "../../../../assets/old_ravian_assests/add.png";
import { confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import editIcon from "../../../../assets/old_ravian_assests/edit_icon.png";
import config from "../../../../config";
import EditAddForm from "./edit_add_form";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { format } from "date-fns";
import Loader from "../../components/loader";
import Paginator from "../../components/paginator";
import useDebounce from "../../components/hooks/useDebounce";
import CustomImagePreview from "../../components/custom_imagepreview";
import Details from "./details";

export default function NewsScreen() {
  const announcementReducer = useSelector((state) => state.announcement || {});
  const { announcementList, loading, totalRecords } = announcementReducer;

  const [filterdList, setFilteredList] = useState();
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [filterSelectedStatus, setFilterSelectedStatus] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const debouncedGlobalFilter = useDebounce(globalFilter, 500);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const formattedExpiryDate = expiryDate
    ? format(expiryDate, "yyyy-MM-dd")
    : null;

  useEffect(() => {
    dispatch(
      getAnnouncementList({
        date: formattedExpiryDate,
        periorty: filterSelectedStatus,
        search: debouncedGlobalFilter || null,
        page: page,
        recordsPerPage: rows,
      })
    );
  }, [
    dispatch,
    filterSelectedStatus,
    expiryDate,
    page,
    rows,
    debouncedGlobalFilter,
  ]);

  useEffect(() => {
    if (announcementList) {
      setFilteredList(announcementList?.data);
    }
  }, [announcementList]);

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
  };

  const filterStatusValue = [
    { label: "High", value: "high" },
    { label: "Medium", value: "medium" },
    { label: "Low", value: "low" },
  ];

  let deleteId;
  const confirmDeleteAnnouncement = () => {
    confirmDialog({
      message: "Are you sure you want to delete this news?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        dispatch(deleteAnnouncement(deleteId));
        toast.success("News deleted successfully");
      },
    });
  };

  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const onHide = () => {
    setShowDialog(false);
    setShowDetailsDialog(false);
  };

  const handleDetailsClick = (rowData) => {
    setSelectedData(rowData);
    setShowDetailsDialog(true);
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          className="p-button-rounded p-button-edit p-0"
          onClick={() => {
            toggleDialogMode(true);
            setSelectedAnnouncement(rowData);
          }}
          aria-label="Edit"
        >
          <img src={editIcon} alt="Edit" />
        </Button>
        <Button
          className="p-button-rounded p-button-delete"
          onClick={() => {
            deleteId = rowData.id;
            confirmDeleteAnnouncement();
          }}
          aria-label="Delete"
        >
          <img src={deleteIcon} alt="Delete" />
        </Button>
        <Button
          className="secondary-btn"
          aria-label="Details"
          onClick={() => handleDetailsClick(rowData)}
        >
          Details
        </Button>
      </div>
    );
  };

  const imageTemplate = (rowData) => {
    const handleImageClick = (event) => {
      event.stopPropagation(); 
    };
  
    return (
      <div onClick={handleImageClick}> 
        <CustomImagePreview src={config.baseUrl + rowData?.icon} />
      </div>
    );
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    setRows(event.rows);
  };

  const exportCSVFile = () => {
    dispatch(getAnnouncementList({})).then(() => {
      if (announcementList && announcementList.data) {
        const csvData = announcementList.data.map((item, index) => ({
          "Sr No": index + 1,
          Title: formatCapitalizeFirstLetter(item, "title"),
          News: formatCapitalizeFirstLetter(item, "announcement"),
          "Date (From)": formatDate(item.createdDate),
          "Date (To)": formatDate(item.expirationDate),
          Priority: formatCapitalizeFirstLetter(item, "periorty"),
        }));
        exportCSV(csvData, "news_data.csv");
      }
    });
  };

  const dt = useRef(null);

  const columnsData = [
    {
      field: "serialNumber",
      header: "Sr. No",
      body: (_, col) => col.rowIndex + 1,
    },
    {
      field: "title",
      header: "Title",
      body: (rowData) => formatCapitalizeFirstLetter(rowData, "title"),
    },
    {
      field: "announcement",
      header: "News",
      body: (rowData) => renderTextWithTooltip(rowData, "announcement", "bottom"),
    },
    {
      field: "createdDate",
      header: "Date (From)",
      body: (rowData) => formatDate(rowData.createdDate),
    },
    {
      field: "expirationDate",
      header: "Date (To)",
      body: (rowData) => formatDate(rowData.expirationDate),
    },
    {
      field: "periorty",
      header: "Priority",
      body: statusColors,
    },

    {
      header: "Image",
      body: imageTemplate,
    },

    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const rowClassName = "p-datatable-clickable";

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-image">
              <img src={isEdit ? editIconRed : addIcon} alt="Icon" />
            </div>
            <div className="popup-header-text">{isEdit ? "Edit" : "Add"}</div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <EditAddForm
          onHide={onHide}
          formData={isEdit ? selectedAnnouncement : null}
          currentPage={page}
          rowsPerPage={rows}
        />
      </Dialog>

      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">News Details</div>
          </div>
        }
        visible={showDetailsDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <Details
          onHide={() => onHide("showDetailsDialog")}
          rowData={selectedData}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        <div className="col-12 md:col-10  justify-content-end filter-responsive">
          <Dropdown
            className="status__dropdown dropdown-size mt-3"
            value={filterSelectedStatus}
            onChange={(e) =>
              setFilterSelectedStatus(e.value !== undefined ? e.value : null)
            }
            optionValue="value"
            options={filterStatusValue}
            optionLabel="label"
            placeholder="Select Status"
            showClear
          />

          <Calendar
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.value)}
            className="calender-size"
            showIcon
            placeholder="Select Date"
            showButtonBar
          />
          <Button
            className="p-button export_button"
            label="Add News"
            icon="pi pi-plus"
            onClick={() => toggleDialogMode(false)}
          />

          <Button
            className="p-button export_button"
            icon="pi pi-upload"
            label="Export to Excel"
            onClick={exportCSVFile}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          scrollable
          scrollHeight="500px"
          ref={dt}
          emptyMessage="No record found."
          value={filterdList}
          className="custom-data-table"
          onRowClick={(event) => handleDetailsClick(event.data)}
          rowClassName={rowClassName}
        >
          {columnsData?.map((data, index) => {
            return (
              <Column
                key={index}
                field={data.field}
                header={data.header}
                body={data.body}
              />
            );
          })}
        </DataTable>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
}
