import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { getBannerList, deleteBanner } from "../../../redux/banner_slice";
import editIcon from "../../../../assets/old_ravian_assests/edit_icon.png";
import deleteIcon from "../../../../assets/old_ravian_assests/delete.png";
import editIconRed from "../../../../assets/old_ravian_assests/edit_icon_red.png";
import addIcon from "../../../../assets/old_ravian_assests/add.png";
import { confirmDialog } from "primereact/confirmdialog";
import {
  formatDate,
  renderTextWithTooltip,
} from "../../components/helper";
import config from "../../../../config";
import { toast } from "react-toastify";
import EditAddForm from "./edit_add_form";
import Loader from "../../components/loader";
import CustomImagePreview from "../../components/custom_imagepreview";

export default function BannerScreen() {
  const bannerReducer = useSelector((state) => state.banner || {});
  const { bannerList, loading } = bannerReducer;

  const [filterdList, setFilteredList] = useState();
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBannerList({}));
  }, [dispatch]);

  useEffect(() => {
    if (bannerList) {
      setFilteredList(bannerList?.data);
    }
  }, [bannerList]);

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
    const filterdData = bannerList.data?.filter((list) =>
      list.description.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredList(filterdData);
  };

  let deleteId;
  const confirmDeleteBanner = () => {
    confirmDialog({
      message: "Are you sure you want to delete this banner?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        dispatch(deleteBanner(deleteId));
        toast.success("Banner deleted successfully");
      },
    });
  };

  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const onHide = () => {
    setShowDialog(false);
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          className="p-button-rounded p-button-edit p-0"
          onClick={() => {
            toggleDialogMode(true);
            setSelectedBanner(rowData);
          }}
          aria-label="Edit"
        >
          <img src={editIcon} alt="Edit" />
        </Button>
        <Button
          className="p-button-rounded p-button-delete"
          onClick={() => {
            deleteId = rowData.id;
            confirmDeleteBanner();
          }}
          aria-label="Delete"
        >
          <img src={deleteIcon} alt="Delete" />
        </Button>
      </div>
    );
  };

  const imageTemplate = (rowData) => {
    return (
      <div>
        <CustomImagePreview src={config.baseUrl + rowData?.banner} />
      </div>
    );
  };

  const columnsData = [
    {
      field: "serialNumber",
      header: "Sr. No",
      body: (_, col) => col.rowIndex + 1,
    },
    {
      field: "description",
      header: "Description",
      body: (rowData) =>
        renderTextWithTooltip(rowData, "description", "bottom"),
    },

    {
      field: "expireDate",
      header: "Expire Date",
      body: (rowData) => formatDate(rowData.expireDate),
    },
    {
      header: "Image",
      body: imageTemplate,
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-image">
              <img src={isEdit ? editIconRed : addIcon} alt="Icon" />
            </div>
            <div className="popup-header-text">
              {isEdit ? "Edit Banner" : "Add Banner"}
            </div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <EditAddForm
          onHide={onHide}
          formData={isEdit ? selectedBanner : null}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        <div className="col-12 md:col-10 justify-content-end filter-responsive">
          <Button
            label="Add Banner"
            icon="pi pi-plus"
            className="p-button ml-3"
            onClick={() => toggleDialogMode(false)}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          scrollable
          scrollHeight="500px"
          emptyMessage="No record found."
          value={filterdList}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
        >
          {columnsData?.map((data, index) => {
            return (
              <Column
                key={index}
                field={data.field}
                header={data.header}
                body={data.body}
              />
            );
          })}
        </DataTable>
      </div>
    </>
  );
}
