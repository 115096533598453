import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";

const membershipReducerExport = createSlice({
  name: "membershipExport",
  initialState: {
    membershipExport: [],
    loading: false,
    error: null,
    totalRecords: 0,
  },
  reducers: {
    resetMembershipState(state, action) {
      state.membershipExport = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMembershipListExport.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMembershipListExport.fulfilled, (state, action) => {
        state.loading = false; 
        state.error = null;
        state.membershipExport = action.payload;
        state.totalRecords = action.payload.count;
      })
      .addCase(getMembershipListExport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default membershipReducerExport.reducer;
export const { resetMembershipState } = membershipReducerExport.actions;

// Thunk to fetch membership list
export const getMembershipListExport = createAsyncThunk(
  "membership/fetch",
  async (
    { fromDate, toDate, status, search, page, recordsPerPage },
    { rejectWithValue }
  ) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}${config.membershipPath}?status=${status}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&recordsPerPage=${recordsPerPage}&search=${search}`
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
