import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { Ripple } from "primereact/ripple";
import { Badge } from "primereact/badge";
import logo from "../../../assets/old_ravian_assests/logo.png";

const AppSubmenu = (props) => {
  const defaultOpenIndex = 0;

  const [activeIndex, setActiveIndex] = useState(defaultOpenIndex);

  const renderLinkContent = (item) => {
    let submenuIcon = item.items && (
      <i className='pi pi-fw pi-angle-down menuitem-toggle-icon'></i>
    );
    let badge = item.badge && <Badge value={item.badge} />;
    let customIcon = item.icon && (
      <img src={item.icon} alt={item.label} className='custom-menu-icon' />
    );

    return (
      <React.Fragment>
        {customIcon}
        <i className={item.icon}></i>
        <span>{item.label}</span>
        {submenuIcon}
        {badge}
        <Ripple />
      </React.Fragment>
    );
  };

  const renderLink = (item, i) => {
    let content = renderLinkContent(item);

    const handleClick = () => {
      if (props.onMenuItemClick) {
        props.onMenuItemClick({ item });
      }
    };

    if (item.to) {
      return (
        <NavLink
          aria-label={item.label}
          role='menuitem'
          className='p-ripple'
          activeClassName='router-link-active router-link-exact-active'
          to={item.to}
          exact
          target={item.target}
          onClick={handleClick}
        >
          {content}
        </NavLink>
      );
    } else {
      return (
        <a
          tabIndex='0'
          aria-label={item.label}
          role='menuitem'
          href={item.url}
          className='p-ripple'
          target={item.target}
          onClick={handleClick}
        >
          {content}
        </a>
      );
    }
  };

  let items =
    props.items &&
    props.items.map((item, i) => {
      let active = activeIndex === i;
      let styleClass = classNames(item.badgeStyleClass, {
        "layout-menuitem-category": props.root,
        "active-menuitem": active && !item.to,
      });

      if (props.root) {
        return (
          <li className={styleClass} key={i} role='none'>
            {props.root === true && (
              <React.Fragment>
                <div
                  className='layout-menuitem-root-text'
                  aria-label={item.label}
                >
                  {item.label}
                </div>
                <AppSubmenu
                  items={item.items}
                  onMenuItemClick={props.onMenuItemClick}
                />
              </React.Fragment>
            )}
          </li>
        );
      } else {
        return (
          <li className={styleClass} key={i} role='none'>
            {renderLink(item, i)}
            <CSSTransition
              classNames='layout-submenu-wrapper'
              timeout={{ enter: 1000, exit: 450 }}
              in={active}
              unmountOnExit
            >
              <AppSubmenu
                items={item.items}
                onMenuItemClick={props.onMenuItemClick}
              />
            </CSSTransition>
          </li>
        );
      }
    });

  return items ? (
    <ul className={props.className} role='menu'>
      {items}
    </ul>
  ) : null;
};

export const AppMenu = (props) => {
  return (
    <>
      <div className='layout-menu-container'>
        <div className='mx-auto logo-section'>
          <img src={logo} />
          <h6>
            Old Ravians Union <br />
            <small>Govt. College University Lahore</small>
          </h6>
        </div>
        <AppSubmenu
          items={props.model}
          className='layout-menu'
          onMenuItemClick={props.onMenuItemClick}
          root={true}
          role='menu'
          icon={props.icon}
        />
      </div>
     
     
    </>
  );
};
