import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

const MixChart = ({ collectionState = [] }) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (!Array.isArray(collectionState) || collectionState.length === 0) {
      console.error("Invalid or empty collectionState data");
      return;
    }

    const membershipCollection = collectionState.map(
      (item) => parseInt(item.membershipCollection, 10) || 0
    );
    const donationCollection = collectionState.map(
      (item) => parseInt(item.donationCollection, 10) || 0
    );
    const months = collectionState.map((item) => {
        const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
      return monthNames[item.month - 1];
    });

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    const data = {
      labels: months,
      datasets: [
        {
          label: "Membership Collection",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: membershipCollection,
        },
        {
          label: "Donation Collection",
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          data: donationCollection,
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
            display: true, 
            position: 'bottom',
            labels: {
                usePointStyle: false,
                boxWidth: 15,
                padding: 30
            }
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (context) {
              return context.dataset.label + ": " + context.raw;
            },
          },
        },
      },

      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [collectionState]);

  return (
    <div className="">
      <Chart type="bar" data={chartData} options={chartOptions} />
    </div>
  );
};

export default MixChart;
