import React from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import config from "../../../../config";
import {
  formatCapitalizeFirstLetter,
  formatDate,
} from "../../components/helper";
import { format } from "date-fns";
import ActionButtons from "../../components/action_buttons";

const formatTime = (time) => {
  if (!time) return "";
  const timeStr = time.toString().padStart(4, "0");
  const formattedTime = `${timeStr.slice(0, 2)}:${timeStr.slice(2)}`;
  const date = new Date(`1970-01-01T${formattedTime}:00`);
  return format(date, "hh:mm a");
};

const formatGuidelines = (guidelines) => {
  if (!guidelines || !Array.isArray(guidelines)) return "";
  return guidelines
    .map((item) => {
      const capitalizedItem = item.charAt(0).toUpperCase() + item.slice(1);
      return `• ${capitalizedItem}`;
    })
    .join("\n");
};

const Details = ({ onHide, rowData }) => {
  const fields = [
    {
      id: "openingDate",
      label: "Opening Date",
      value: formatDate(rowData ? rowData.registrationOpeningDate : null),
    },
    {
      id: "closingDate",
      label: "Closing Date",
      value: formatDate(rowData ? rowData.registrationClosingDate : null),
    },
    {
      id: "eventDate",
      label: "Event Date",
      value: formatDate(rowData ? rowData.eventDate : null),
    },
    {
      id: "title",
      label: "Title",
      value: formatCapitalizeFirstLetter(rowData, "title") || "",
    },
    {
      id: "fee",
      label: "Ticket Price",
      value: rowData ? `PKR ${rowData.fee || ""}` : "",
    },
    {
      id: "seats",
      label: "Total Seats",
      value: rowData ? rowData.totalSeats || "" : "",
    },
    {
      id: "eventStartTime",
      label: "Start Time",
      value: rowData ? formatTime(rowData.eventStartTime) : "",
    },
    {
      id: "eventEndTime",
      label: "End Time",
      value: rowData ? formatTime(rowData.eventEndTime) : "",
    },
    {
      id: "city",
      label: "Location",
      value:
        rowData && rowData.location
          ? formatCapitalizeFirstLetter(rowData.location, "city")
          : "--",
    },
    {
      id: "address",
      label: "Address",
      value:
        rowData && rowData.location
          ? formatCapitalizeFirstLetter(rowData.location, "address")
          : "--",
    },
    {
      id: "guidelines",
      label: "Guidelines",
      value: formatGuidelines(rowData ? rowData.guidelines : []),
    },
    {
      id: "description",
      label: "Description",
      value: formatCapitalizeFirstLetter(rowData, "description") || "",
    },
  ];

  return (
    <>
      <div className="p-fluid formgrid grid">
        {fields.map((field) => (
          <div
            className={`field md:col-${
              field.id === "description" || field.id === "guidelines"
                ? "12"
                : "4"
            }`}
            key={field.id}
          >
            <label htmlFor={field.id}>{field.label}</label>
            {field.id === "description" || field.id === "guidelines" ? (
              <>
                <InputTextarea
                  id={field.id}
                  value={field.value}
                  readOnly
                  rows={5}
                />
              </>
            ) : (
              <InputText id={field.id} value={field.value} readOnly />
            )}
          </div>
          
        ))}
        
      </div>
      <div className="col-12 md:12 p-fluid add_banner">
        <div className="flex">
          <div className="upload_detail_images">
            <h6>Ticket Image</h6>
            {rowData && rowData.ticket && rowData.ticket && (
              <img src={config.baseUrl + rowData.ticket} alt="Ticket Image" />
            )}
          </div>
        </div>
      </div>
      <div className="details-action-button">
        <ActionButtons onCancel={onHide} cancelLabel="Close" />
      </div>{" "}
    </>
  );
};

export default Details;
