import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardList } from "../../../redux/dashbaord_slice";
import ComplaintChart from "./complaint_chart";
import BarChart from "./bar_chart";
import MixChart from "./mix_chart";
import DonationtypeChart from "./donationtype_chart";
import { ProgressSpinner } from 'primereact/progressspinner'; 
import stats1 from "../../../../assets/old_ravian_assests/stats.png";
import stats2 from "../../../../assets/old_ravian_assests/stats1.png";
import stats3 from "../../../../assets/old_ravian_assests/stats2.png";
import Loader from "../../components/loader";

export default function DashboardScreen() {
  const dashboardReducer = useSelector((state) => state.dashboard || {});
  const { dashboardList, loading } = dashboardReducer;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboardList({}));
  }, [dispatch]);

  const complaintsData = dashboardList?.data?.complaints[0] || {};

  const tilesData = [
    {
      title: "Total Members",
      value: dashboardList?.data?.totalActiveMembers,
      backgroundImage: stats1,
    },
    {
      title: "Total Donations",
      value: (
        <>
          <span className='italic'>PKR</span>{" "}
          {dashboardList?.data?.totalDonations}
        </>
      ),
      backgroundImage: stats2,
    },
    {
      title: "Total Complaints",
      value: complaintsData.totalComplaints,
      backgroundImage: stats3,
    },
  ];

  return (
    <>
    {
      loading &&  <Loader />
    }
      <div className='stats-content'>
        <div className='grid titles-grid'>
          {tilesData.map((tile, index) => (
            <div className='md:col-12 lg:col-4 mobile-graphs' key={index}>
              <div
                className='tiles'
                style={{ backgroundImage: `url(${tile.backgroundImage})` }}
              > 
                <h2>{tile.title}</h2>
                <h1>{tile.value}</h1>
              </div>
            </div>
          ))}
        </div>
        {/* graphs */}
        <div className='grid'>
        <div className='md:col-12 lg:col-6 mobile-graphs'>
            <div className='card charts-card'>
              <h6>Active Members</h6>
              <BarChart
                membershipState={dashboardList?.data?.membershipState}
              />
            </div>
          </div>
          <div className='md:col-12 lg:col-6 mobile-graphs'>
            <div className='card charts-card'>
              <h6>Amount Collected</h6>
              <MixChart collectionState={dashboardList?.data?.collectionState} />
            </div>
          </div>
        <div className='md:col-12 lg:col-6 mobile-graphs'>
            <div className='card charts-card'>
              <h6>Donation Type</h6>
              <DonationtypeChart donationTypeStates={dashboardList?.data?.donationTypeStates}/>
            </div>
          </div>
          <div className='md:col-12 lg:col-6 mobile-graphs'>
            <div className='card charts-card'>
              <h6>Complaints</h6>
              <ComplaintChart complaints={dashboardList?.data?.complaints} />
            </div>
          </div>
         
        </div>
      </div>
    </>
  );
}
