import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import {
  getGalleryList,
  deleteGallery,
} from "../../../redux/gallery_images_slice/gallery_slice";
import deleteIcon from "../../../../assets/old_ravian_assests/delete.png";
import editIconRed from "../../../../assets/old_ravian_assests/edit_icon_red.png";
import addIcon from "../../../../assets/old_ravian_assests/add.png";
import AddCategory from "./add_category";
import AddGalleryImages from "./add_gallery_images";
import config from "../../../../config";
import Loader from "../../components/loader";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Galleria } from "primereact/galleria";

import {
  formatCapitalizeFirstLetter,
  formatDate,
  renderTextWithTooltip,
} from "../../components/helper";
import { toast } from "react-toastify";
import { format } from "date-fns";

export default function GalleryScreen() {
  const galleryReducer = useSelector((state) => state.gallery || {});
  const { galleryList, loading } = galleryReducer;

  const [filterdList, setFilteredList] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [date, setDate] = useState(null);
  const [type, setType] = useState("image");
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState(null);
  const [filterSelectedYear, setFilterSelectedYear] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedGallery, setSelectedGallery] = useState([]);
  const [carouselVisible, setCarouselVisible] = useState(false);

  const dispatch = useDispatch();

  const formattedDate = date ? format(date, "yyyy-MM-dd") : null;

  useEffect(() => {
    dispatch(
      getGalleryList({
        date: formattedDate,
        type: type,
        page: page,
        category: category,
        year: filterSelectedYear,
      })
    );
  }, [dispatch, date, filterSelectedYear]);

  useEffect(() => {
    if (galleryList) {
      setFilteredList(galleryList?.data);
    }
  }, [galleryList]);

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
    const filterdData = galleryList.data?.filter(
      (list) =>
        list.title.toLowerCase().includes(searchValue.toLowerCase()) ||
        list.description.toLowerCase().includes(searchValue.toLowerCase()) ||
        list.category.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredList(filterdData);
  };

  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const onHide = () => {
    setShowDialog(false);
  };

  let deleteId;
  const confirmDeleteGallery = () => {
    confirmDialog({
      message: "Are you sure you want to delete this gallery?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        dispatch(deleteGallery(deleteId));
        toast.success("Gallery deleted successfully");
      },
    });
  };

  const imageTemplate = (rowData) => {
    return (
      <div>
        {rowData.gallery && rowData.gallery.length > 0 && (
          <div>
            <img
              src={config.baseUrl + rowData.gallery[0]}
              className="datatable-profile-image"
              onClick={() => {
                setSelectedGallery(rowData.gallery);
                setCarouselVisible(true);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          className="p-button-rounded p-button-delete"
          onClick={() => {
            deleteId = rowData.id;
            confirmDeleteGallery();
          }}
          aria-label="Delete"
        >
          <img src={deleteIcon} alt="Delete" />
        </Button>
      </div>
    );
  };

  const columnsData = [
    {
      field: "serialNumber",
      header: "Sr. No",
      body: (_, col) => col.rowIndex + 1,
    },
    {
      field: "title",
      header: "Title",
      body: (rowData) => formatCapitalizeFirstLetter(rowData, "title"),
    },
    {
      field: "description",
      header: "Description",
      body: (rowData) =>
        renderTextWithTooltip(rowData, "description", "bottom"),
    },
    {
      field: "category",
      header: "Category",
      body: (rowData) => formatCapitalizeFirstLetter(rowData, "category"),
    },
    {
      field: "type",
      header: "Type",
      body: (rowData) => formatCapitalizeFirstLetter(rowData, "type"),
    },
    {
      field: "year",
      header: "Year",
    },
    {
      field: "createdDate",
      header: "Date",
      body: (rowData) => formatDate(rowData.createdDate),
    },
    {
      header: "Image",
      body: imageTemplate,
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 10 }, (_, i) => ({
    label: (currentYear - i).toString(),
    value: (currentYear - i).toString(),
  }));

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "960px",
      numVisible: 4,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  const images = selectedGallery.map((imageUrl, index) => ({
    itemImageSrc: config.baseUrl + imageUrl,
    thumbnailImageSrc: config.baseUrl + imageUrl,
    alt: `Image ${index}`,
  }));

  const itemTemplate = (item) => {
    return (
      <div style={{ textAlign: "center", margin: "auto" }}>
        <img
          src={item.itemImageSrc}
          alt={item.alt}
          style={{
            width: "100%",
            height: "400px",
            objectFit: "cover",
            margin: "auto",
          }}
        />
      </div>
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.thumbnailImageSrc}
        alt={item.alt}
        style={{ width: "100px", height: "100px", objectFit: "cover" }}
      />
    );
  };

  return (
    <>
      <Dialog
        header={
          <div className="dialog-close-icon">
            <Button
              icon="pi pi-times"
              className="p-dialog-titlebar-icon p-dialog-titlebar-close "
              onClick={() => setCarouselVisible(false)}
            />
          </div>
        }
        className="gallery-dialog"
        visible={carouselVisible}
        onHide={() => setCarouselVisible(false)}
      >
        <Galleria
          value={images}
          responsiveOptions={responsiveOptions}
          numVisible={5}
          circular
          item={itemTemplate}
          thumbnail={thumbnailTemplate}
          autoPlay
          transitionInterval={2000}
          showThumbnails={true}
          showItemNavigators
          showItemNavigatorsOnHover
        />
      </Dialog>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-image">
              <img src={isEdit ? editIconRed : addIcon} alt="Icon" />
            </div>
            <div className="popup-header-text">
              {isEdit ? "Add Gallery" : "Add"}
            </div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        {isEdit ? (
          <AddGalleryImages onHide={onHide} />
        ) : (
          <AddCategory onHide={onHide} />
        )}
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        <div className="col-12 md:col-10 justify-content-end filter-responsive">
          <Dropdown
            className="status__dropdown dropdown-size mb-0"
            value={filterSelectedYear}
            // onChange={(e) => setFilterSelectedYear(e.target.value)}
            onChange={(e) =>
              setFilterSelectedYear(e.value !== undefined ? e.value : null)
            }
            optionValue="value"
            options={yearOptions}
            optionLabel="label"
            placeholder="Select Year"
            showClear
          />
          <Calendar
            value={date}
            onChange={(e) => setDate(e.value)}
            className="calender-size"
            showIcon
            placeholder="Select Date"
          />
          <Button
            label="Add Category"
            icon="pi pi-plus"
            className="p-button ml-3"
            onClick={() => toggleDialogMode(false)}
          />
          <Button
            label="Add Gallery"
            icon="pi pi-plus"
            className="p-button ml-3"
            onClick={() => toggleDialogMode(true)}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          scrollable
          scrollHeight="500px"
          emptyMessage="No record found."
          paginator
          rows={10}
          value={filterdList}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
        >
          {columnsData?.map((data, index) => {
            return (
              <Column
                key={index}
                field={data.field}
                header={data.header}
                body={data.body}
              />
            );
          })}
        </DataTable>
      </div>
    </>
  );
}
