import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createBanner,
  updateBanner,
  getBannerList,
} from "../../../redux/banner_slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Calendar } from "primereact/calendar";
import addIcon from "../../../../assets/old_ravian_assests/add.png";
import { InputText } from "primereact/inputtext";
import config from "../../../../config";
import { toast } from "react-toastify";
import ActionButtons from "../../components/action_buttons";
import { InputTextarea } from "primereact/inputtextarea";

const EditAddForm = ({ onHide, formData }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isImageEdit, setIsImageEdit] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const formik = useFormik({
    initialValues: {
      description: formData?.description || "",
      expireDate: formData?.expireDate || "",
      banner: formData ? `${config.baseUrl}${formData.banner}` : "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required("Please add a description"),
      expireDate: Yup.date().required("Please add expiry date"),
      banner: Yup.string().required("Please upload an image"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!formChanged) {
        onHide();
        return;
      }

      const updatePayload = {
        description: values.description,
        expireDate: values.expireDate,
        banner: isImageEdit ? values.banner : undefined,
      };

      try {
        if (formData) {
          await dispatch(updateBanner({ id: formData.id, ...updatePayload }));
          toast.success("Banner updated successfully");
        } else {
          await dispatch(createBanner(values));
          toast.success("Banner added successfully");
        }

        onHide();
        await dispatch(getBannerList({}));
      } catch (error) {
        toast.error(`Failed to ${formData ? "update" : "add"} banner`);
      }
    },
  });

  const handleFileUpload = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = async () => {
        const base64String = reader.result;
        setIsImageEdit(true);
        formik.setFieldValue("banner", base64String);
        setFormChanged(true);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid ">
          <div className="field md:col-4 responsive-col">
            <label htmlFor="expireDate">
              Expire Date{!formData && <span className="asteric">&nbsp;*</span>}
            </label>
            <Calendar
              id="expireDate"
              value={new Date(formik.values.expireDate)}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                selectedDate.setMinutes(
                  selectedDate.getMinutes() - selectedDate.getTimezoneOffset()
                );
                formik.setFieldValue("expireDate", selectedDate.toISOString());
                formik.setFieldError("expireDate", "");
                setFormChanged(true);
              }}
              showIcon
              placeholder="Select Date"
              minDate={new Date()}
            />
            {formik.touched.expireDate && formik.errors.expireDate && (
              <small className="error">{formik.errors.expireDate}</small>
            )}
          </div>
          <div className="field md:col-12 responsive-col">
            <label htmlFor="description">
              Description{!formData && <span className="asteric">&nbsp;*</span>}
            </label>
            <InputTextarea
              id="description"
              rows={5}
              name="description"
              value={formik.values.description}
              onChangeCapture={(e) => {
                formik.handleChange(e);
                setFormChanged(true);
              }}
              className={
                formik.errors.description && formik.touched.description
                  ? "p-invalid"
                  : null
              }
            />
            {formik.touched.description && formik.errors.description && (
              <small className="error">{formik.errors.description}</small>
            )}
          </div>

          <div className="field md:col-4 responsive-col">
            <label htmlFor="file-upload" className="block mb-2 upload_images">
              Upload File{!formData && <span className="asteric">&nbsp;*</span>}
              {!formik.values.banner ? (
                <>
                  <div className="upload-box">
                    <img src={addIcon} alt="Add Icon" />
                  </div>
                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                    accept=".png, .jpg, .jpeg, .svg"
                  />
                </>
              ) : (
                <div className="upload-box">
                  <img src={formik.values.banner} alt="Banner Preview" />
                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                    accept=".png, .jpg, .jpeg, .svg"
                  />
                </div>
              )}
              {formik.touched.banner && formik.errors.banner && (
                <small className="error">{formik.errors.banner}</small>
              )}
            </label>
          </div>
        </div>

        <ActionButtons
          showSave
          onCancel={onHide}
          onSave={formik.handleSubmit}
          loading={formik.isSubmitting}
          saveLabel={formData ? "Update" : "Add"}
          cancelLabel="Close"
        />
      </form>
    </>
  );
};

export default EditAddForm;
