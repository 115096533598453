import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateUser, getUserList } from "../../../redux/auth_slice/user_slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import ActionButtons from "../../components/action_buttons";
import config from "../../../../config";

const EditAddForm = ({ onHide, formData, currentPage, rowsPerPage }) => {
  const dispatch = useDispatch();
  const [formChanged, setFormChanged] = useState(false);
  const [statusSelected, setStatusSelected] = useState(false);

  const formik = useFormik({
    initialValues: {
      is_verified:
        formData?.is_verified !== undefined ? formData.is_verified : "",
      document: formData ? `${config.baseUrl}${formData.document}` : "",
    },

    validationSchema: Yup.object({
      is_verified: Yup.string().required("Please select a status"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (!formChanged || values.is_verified === "Reject") {
        onHide();
        setSubmitting(false);
        return;
      }
      const updatePayload = {
        is_verified: values.is_verified === "Accept",
      };

      try {
        if (formData) {
          await dispatch(updateUser({ id: formData.id, ...updatePayload }));
          toast.success("User status updated successfully");
        }
        onHide();
        await dispatch(
          getUserList({
            id: formData?.role?.id,
            page: currentPage,
            recordsPerPage: rowsPerPage,
            search: null,
            isExecutive: false,
          })
        );
      } catch (error) {
        toast.error(`Failed to update user status`);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleStatusChange = (e) => {
    formik.setFieldValue("is_verified", e.value);
    setFormChanged(true);
    setStatusSelected(true);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field md:col-12 responsive-col">
            <label className="m-0">Status</label>
            <Dropdown
              className="status__dropdown mt-3"
              value={formik.values.is_verified}
              onChange={handleStatusChange}
              options={[
                { label: "Accept", value: "Accept" },
                { label: "Reject", value: "Reject" },
              ]}
              optionLabel="label"
              placeholder="Select"
            />
            {formik.touched.is_verified &&
            formik.errors.is_verified &&
            !statusSelected ? (
              <small className="p-error">{formik.errors.is_verified}</small>
            ) : null}
          </div>
        </div>

        <ActionButtons
          showSave
          onCancel={onHide}
          onSave={formik.handleSubmit}
          loading={formik.isSubmitting}
          saveLabel={formData ? "Update" : "Add"}
          disabled={!statusSelected}
          cancelLabel="Close"
        />
      </form>
    </>
  );
};

export default EditAddForm;
