import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppTopbar } from "./app/features/components/app_top_bar";
import { AppMenu } from "./app/features/components/app_menu";
import { AppConfig } from "./AppConfig";
import { ToastContainer } from "react-toastify";
import LoginScreen from "./app/features/screens/auth/loginscreen";
import MembershipScreen from "./app/features/screens/membership/membership";
import NewsScreen from "./app/features/screens/news/news";
import BannerScreen from "./app/features/screens/banner/banner";
import ComingSoonDialog from "./app/features/components/coming_soon";
import { setUser } from "./app/redux/auth_slice/login_user_slice";
import DashboardScreen from "./app/features/screens/dashbaord/dashboard";
import UserVerificationScreen from "./app/features/screens/user_verification/user_verification";
import HelpScreen from "./app/features/screens/help/help";
import DonationScreen from "./app/features/screens/donations/donation";
import EventsScreen from "./app/features/screens/events/events";
import GalleryScreen from "./app/features/screens/gallery/gallery";
import "react-toastify/dist/ReactToastify.css";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import "primereact/resources/primereact.css";
import copyright from "./assets/old_ravian_assests/copyright.png";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./styles/app.scss";
import "./styles/login.scss";
import CommitteeScreen from "./app/features/screens/committee/committee";

const App = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  const copyTooltipRef = useRef();
  const location = useLocation();
  const history = useHistory();

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);

      if (
        event.item.label === "News" ||
        event.item.label === "Gallery" ||
        event.item.label === "Chat Room" ||
        event.item.label === "ORU Elections" ||
        event.item.label === "Donations" ||
        event.item.label === "Executive Committee" ||
        event.item.label === "Souvenirs" ||
        event.item.label === "Boost your Business" ||
        event.item.label === "Shop" ||
        event.item.label === "Memberships"
      ) {
        setDialogVisible(true);
      } else {
        setDialogVisible(false);
      }
    }
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  //Show current path on AppTopBar
  const findCurrentRouteLabel = (location) => {
    const path = location.pathname;
    let currentRouteLabel = "";

    for (const group of menu) {
      for (const subGroup of group.items || []) {
        if (!subGroup.items) {
          // If there are no sub-items, check if the item itself matches the path
          if (subGroup.to === path) {
            return subGroup.label || "";
          }
        } else {
          for (const item of subGroup.items || []) {
            if (item.to === path) {
              const labels = [group.label, subGroup.label, item.label].filter(
                (label) => label
              ); // Filter out empty labels
              currentRouteLabel = labels.join(" - "); // Join labels with dash
              return currentRouteLabel;
            }
          }
        }
      }
    }
    return currentRouteLabel;
  };

  const menu = [
    {
      items: [
        {
          label: "Dashboard",
          icon: require("./assets/old_ravian_assests/dashboard.png"),
          to: "/",
        },
      ],
    },
    {
      items: [
        {
          label: "User Verification",
          icon: require("./assets/old_ravian_assests/verified.png"),
          to: "/user-verification",
        },
      ],
    },
    {
      items: [
        {
          label: "Memberships",
          icon: require("./assets/old_ravian_assests/membership.png"),
          to: "/memberships",
        },
      ],
    },
    {
      items: [
        {
          label: "Executive Committee",
          icon: require("./assets/old_ravian_assests/comittee.png"),
          to: "/committee",
        },
      ],
    },
    
    {
      items: [
        {
          label: "Donations",
          icon: require("./assets/old_ravian_assests/donations.png"),
          to: "/donations",
        },
      ],
    },
    {
      items: [
        {
          label: "Gallery",
          icon: require("./assets/old_ravian_assests/gallery.png"),
          to: "/gallery",
        },
      ],
    },
    {
      items: [
        {
          label: "News",
          icon: require("./assets/old_ravian_assests/news.png"),
          to: "/news",
        },
      ],
    },
    {
      items: [
        {
          label: "Events",
          icon: require("./assets/old_ravian_assests/events.png"),
          to: "/events",
        },
      ],
    },
    // {
    //   items: [
    //     {
    //       label: "Chat Room",
    //       icon: require("./assets/old_ravian_assests/chat.png"),
    //       to: "/chatroom",
    //     },
    //   ],
    // },
    // {
    //   items: [
    //     {
    //       label: "ORU Elections",
    //       icon: require("./assets/old_ravian_assests/elections.png"),
    //       to: "/elections",
    //     },
    //   ],
    // },

    {
      items: [
        {
          label: "Banner Management",
          icon: require("./assets/old_ravian_assests/banner.png"),
          to: "/banner",
        },
      ],
    },
    {
      items: [
        {
          label: "Help",
          icon: require("./assets/old_ravian_assests/help.png"),
          to: "/help",
        },
      ],
    },

    // {
    //   items: [
    //     {
    //       label: "Souvenirs",
    //       icon: require("./assets/old_ravian_assests/sovenier.png"),
    //       to: "/souvenirs",
    //     },
    //   ],
    // },
    // {
    //   items: [
    //     {
    //       label: "Boost your Business",
    //       icon: require("./assets/old_ravian_assests/business.png"),
    //       to: "/boostbusiness",
    //     },
    //   ],
    // },
    // {
    //   items: [
    //     {
    //       label: "Shop",
    //       icon: require("./assets/old_ravian_assests/shop.png"),
    //       to: "/shop",
    //     },
    //   ],
    // },
  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  const wrapperStyle = {
    // overflowY: "hidden", // Add overflow-y: hidden to hide vertical scrollbar
  };

  const routes = [
    { path: "/login", component: LoginScreen },
    { path: "/", component: DashboardScreen },
    { path: "/user-verification", component: UserVerificationScreen },
    { path: "/news", component: NewsScreen },
    { path: "/events", component: EventsScreen },
    { path: "/gallery", component: GalleryScreen },
    { path: "/donations", component: DonationScreen },
    { path: "/help", component: HelpScreen },
    { path: "/committee", component: CommitteeScreen },
    { path: "/memberships", component: MembershipScreen },
    { path: "/banner", component: BannerScreen },
  ];

  // Check if the current route matches any of the excluded routes
  const excludedRoutes = routes.map((route) => route.path);
  const showComingSoonDialog = !excludedRoutes.includes(location.pathname);

  const loginUser = useSelector((state) => state.loginUser);
  const { user } = loginUser;
  const dispatch = useDispatch();

  const userData = localStorage.getItem("user");

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      dispatch(setUser(JSON.parse(userData)));
    }
  }, []);

  useEffect(() => {
    if (!user && !localStorage.getItem("user")) {
      history.push("/login");
    }
  }, [user, history]);

  return (
    <>
      <ToastContainer />

      <Switch>
        <Route exact path="/login" component={LoginScreen} />
        <Route path="/">
          {localStorage.getItem("user") === null ? (
            <Redirect to="/login" />
          ) : (
            <>
              <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip
                  ref={copyTooltipRef}
                  target=".block-action-copy"
                  position="bottom"
                  content="Copied to clipboard"
                  event="focus"
                />
                <div className="layout-sidebar" onClick={onSidebarClick}>
                  <AppMenu
                    model={menu}
                    onMenuItemClick={onMenuItemClick}
                    layoutColorMode={layoutColorMode}
                  />
                </div>

                <div className="layout-main-container">
                  <AppTopbar
                    onToggleMenuClick={onToggleMenuClick}
                    layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive}
                    onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                    onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
                    currentRouteLabel={findCurrentRouteLabel(location)}
                    user={user}
                  />
                  <div className="layout-main">
                    {showComingSoonDialog && (
                      <ComingSoonDialog
                        visible={dialogVisible}
                        onHide={() => setDialogVisible(false)}
                      />
                    )}
                    <Switch>
                      <Route exact path="/" component={DashboardScreen} />
                      <Route
                        exact
                        path="/user-verification"
                        component={UserVerificationScreen}
                      />
                      <Route exact path="/news" component={NewsScreen} />
                      <Route exact path='/events' component={EventsScreen} />
                      <Route exact path="/gallery" component={GalleryScreen} />
                      <Route
                        exact
                        path="/donations"
                        component={DonationScreen}
                      />
                      <Route exact path="/help" component={HelpScreen} />
                      <Route
                        exact
                        path="/committee"
                        component={CommitteeScreen}
                      />
                      <Route
                        exact
                        path="/memberships"
                        component={MembershipScreen}
                      />
                      <Route exact path="/banner" component={BannerScreen} />
                    </Switch>
                  </div>
                  <footer className="app-menu-footer">
                    <img src={copyright} />
                    <span>
                      2024 Copyright: GC University Lahore @ Directorate IT-All
                      Rights Reserved.
                    </span>
                  </footer>
                </div>
              </div>

              <AppConfig
                rippleEffect={ripple}
                onRippleEffect={onRipple}
                inputStyle={inputStyle}
                onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode}
                onLayoutModeChange={onLayoutModeChange}
                layoutColorMode={layoutColorMode}
                onColorModeChange={onColorModeChange}
              />

              <CSSTransition
                classNames="layout-mask"
                timeout={{ enter: 200, exit: 200 }}
                in={mobileMenuActive}
                unmountOnExit
              >
                <div className="layout-mask p-component-overlay"></div>
              </CSSTransition>
            </>
          )}
        </Route>
      </Switch>
    </>
  );
};

export default App;
