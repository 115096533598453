import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";


const announcementReducer = createSlice({
  name: "announcement",
  initialState: {
    announcementList: [],
    loading: false,
    error: null,
    totalRecords: 0,
  },
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.addSuccess = undefined;
      state.deleteSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnnouncementList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAnnouncementList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.announcementList = action.payload;
        state.totalRecords = action.payload.count;
      })
      .addCase(getAnnouncementList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createAnnouncement.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAnnouncement.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.addSuccess = action.payload;
        toast.success('News added successfully')
      })
      .addCase(createAnnouncement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error('Failed to add news')
      })
      .addCase(updateAnnouncement.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAnnouncement.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        toast.success('News updated successfully')
      })
      .addCase(updateAnnouncement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error('Failed to update news')
      })
      .addCase(deleteAnnouncement.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.deleteSuccess = undefined;
      })
      .addCase(deleteAnnouncement.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.deleteSuccess = true;
        const filterData = state.announcementList.data.filter(
          (list) => list.id != action.payload.deletedAnnouncementId
        );
        state.announcementList = {
          ...state.announcementList,
          data: filterData,
        };
      })
      .addCase(deleteAnnouncement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.deleteSuccess = false;
      });
  },
});

export default announcementReducer.reducer;
export const { resetChangeStatus } = announcementReducer.actions;

// Thunk to fetch announcement list
export const getAnnouncementList = createAsyncThunk(
  "announcement/fetch",
  async ({date, periorty, search, page, recordsPerPage }, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}${config.announcementPath}/all?date=${date}&periorty=${periorty}&page=${page}&recordsPerPage=${recordsPerPage}&search=${search}`
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to create a new announcement
export const createAnnouncement = createAsyncThunk(
  "announcement/create",
  async (announcementData, { rejectWithValue }) => {
    try {
      const response = await Axios.post(
        `${config.baseUrl}${config.announcementPath}`,
        announcementData
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to delete announcement list
export const deleteAnnouncement = createAsyncThunk(
  "announcement/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.delete(
        `${config.baseUrl}${config.announcementPath}/${id}`
      );
      return { data: response.data, deletedAnnouncementId: id };
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk to update a announcement
export const updateAnnouncement = createAsyncThunk(
  "announcement/update",
  async (updatedAnnouncementData, { rejectWithValue }) => {
    try {
      const { id, ...data } = updatedAnnouncementData;
      const response = await Axios.patch(
        `${config.baseUrl}${config.announcementPath}/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
