import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { createCategory } from "../../../redux/gallery_images_slice/category_slice";
import { toast } from "react-toastify";
import ActionButtons from "../../components/action_buttons";

const currentYear = new Date().getFullYear();
const yearOptions = Array.from({ length: 10 }, (_, i) => ({
  label: (currentYear - i).toString(),
  value: (currentYear - i).toString(),
}));

const AddCategory = ({ onHide }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const validationSchema = Yup.object().shape({
    year: Yup.string().required("Year is required"),
    category: Yup.string().required("Category is required"),
  });

  const formik = useFormik({
    initialValues: {
      year: "",
      category: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { year, category } = values;
      try {
        const response = await dispatch(createCategory({ category, year }));
        if (response.payload === "Already exist!") {
          setError("Category already exists for the selected year.");
        } else {
          toast.success("Category added successfully.");
          onHide();
        }
      } catch (error) {
        setError("Failed to add category. " + error.message);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="p-fluid formgrid grid ">
        <div className="field md:col-6 responsive-col">
          <label htmlFor="year">
            Year<span className="asteric">&nbsp;*</span>
          </label>
          <Dropdown
            id="year"
            value={formik.values.year}
            options={yearOptions}
            onChange={(e) => {
              setError("");
              formik.setFieldValue("year", e.value);
            }}
            placeholder="Select Year"
            className="status__dropdown m-0"
          />
          {formik.touched.year && formik.errors.year && (
            <div className="error">{formik.errors.year}</div>
          )}
        </div>

        <div className="field  md:col-6 responsive-col">
          <label htmlFor="category">
            Category<span className="asteric">&nbsp;*</span>
          </label>
          <InputText
            id="category"
            name="category"
            value={formik.values.category}
            onChange={formik.handleChange}
          />
          {formik.touched.category && formik.errors.category && (
            <div className="error">{formik.errors.category}</div>
          )}
        </div>
        {error && <div className="error pb-3 px-3">{error}</div>}
      </div>
      <ActionButtons
        showSave
        onCancel={onHide}
        onSave={formik.handleSubmit}
        loading={loading}
        saveLabel="Add"
        cancelLabel="Close"
      />
    </form>
  );
};

export default AddCategory;
